@import '../../../../styles/global/variables.less';

.page-billing-payments {
    .button-wrapper {
        padding: 19px 0;
    }

    .total-in, .total-out {
        display: inline-block;
        border-right: 2px solid @border-color-light;
        padding: 18px @block-interval-step*3;
        text-align: right;

        .title {
            text-transform: uppercase;
            color: @text-color-lighten;
        }

        .value {
            font-size: 24px;
            color: @color-primary;
        }
    }

    .total-in .value{
        color: @color-success-darken;
    }

    .total-out .value{
        color: @color-warning-default;
    }

    .data-table {
        tbody {
            td.neutral span{
                visibility: hidden;
            }

            td.inflow{
                color: @color-success-darken;
            }

            td.outflow{
                color: @color-warning-default;
            }
        }
    }
}
