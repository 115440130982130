@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');


@header-height: 60px;

@brand-logo-block-width: 80px;
@brand-name-block-width: 120px;
@profile-block-width: 400px;

@main-menu-height: 60px;

@layout-padding: 100px;
@block-interval-step: 12px;


/* #region media */

@media-width-1280: 1280px;

/* #endregion */

/* #region texts */

@text-color-main: #435261;
@text-color-darken: darken(@text-color-main, 20%);
@text-color-lighten: #808080;
@text-color-light: #B8C7D6;

@text-color-inverse: #e9e9e9;
@text-color-inverse-hover: white;
@text-color-inverse-active: white;


@font-family-default: 'Open Sans Condensed', Tahoma, Geneva, Verdana, sans-serif;
@font-family-test: 'Roboto Condensed', Tahoma, Geneva, Verdana, sans-serif;

@text-size-main: 1em;
@text-size-extra-small: 0.714rem;
@text-size-small: 0.86rem;
@text-size-h4: 1.143rem;
@text-size-h3: 1.286rem;
@text-size-h2: 1.428rem;
@text-size-h1: 1.571rem;

@main-menu-font-size: 14px;

@color-link-default: #2d6fb1;
@color-link-active: darken(@color-link-default, 50%);
@color-link-hover: darken(@color-link-default, 50%);

/* #endregion */


@text-input-height-default: 42px;
@text-input-height-multiline: 84px;

/* #region colors */
@color-body-background: white;
@color-block-background: #f9f9f9;
@color-block-background-hover-primary: #ebf0f6;

@border-color-main: #dddddd;
@border-color-light: #f0f0f0;
@border-color-dark: #bbbbbb;

@color-primary: #8676b6;
@color-primary-pale: #9b92b9;
@color-primary-lighten: #9986d3;
@color-primary-light: #c7bee5;
@color-primary-light-pale: #e7e6ed;
@color-primary-dark: #5e547c;

@color-accent: #ffaa35;
@color-accent-lighten: #fcc579;
@color-accent-light: #ffe3c0;
@color-accent-pale: #e4c293;
@color-accent-lighten-pale: #efdcc1;
@color-accent-light-pale: #eee3d6;
@color-accent-darken: #e47f44;

@color-accent-light-background: @color-accent-light-pale;
@color-accent-secondary-default: @color-accent-light-pale;
@color-accent-secondary-default-hover: @color-accent-light-pale;

@color-disabled: #BFCDDB;
@color-disabled-light: lighten(@color-disabled, 16%);
/*
    use to custom elements only
    not to standart browser inputs
*/
@text-color-custom-elements-disabled: rgb(67, 82, 97);



@color-warning-default: #ed5757;
@color-warning-default-pale: #e69393;
@color-warning-lighten: #f5a9b2;
@color-warning-lighten-pale: #dbcacc;
@color-warning-light: #f8e8ea;
@color-warning-light-pale: #ede8e8;
@color-warning-background: #faeee7;

@color-success-darken: #35971e;
@color-success-default: #39c218;
@color-success-pale: #87c478;
@color-success-lighten: #cef8d7;
@color-success-light: #ebf7ed;


@color-info-default: #dce8f5;
@color-info-lighten: #e4f1fa;


@color-shadow: rgba(127,127,127,0.2);


@background-control-active: @color-accent-lighten;
@background-control-active-hover: fade(@color-accent-light, 30%);
@background-control-inactive: #F2F6FA;
@background-control-inactive-hover: #eef3f8;
@background-control-off: #EBEDF0;
@background-control-off-hover: #CACCCE;
@border-control-active: @color-accent;
@border-control-active-hover: @color-accent-darken;
@border-control-inactive: #BFCDDB;
@border-control-inactive-hover: #bfdbca;
/* #endregion */


/* #region layout */

@main-menu-width: 196px;
@main-menu-width-collapsed: 64px;
@main-menu-item-height: 64px;
@main-menu-padding-lr: 8px;

@header-height: @main-menu-item-height;


@main-menu-background: #444444;
@main-menu-item-text-color: @text-color-inverse;
@main-menu-item-border-color: #565656;

@main-menu-item-active-background: @color-primary;
@main-menu-item-active-text-color: @text-color-inverse-active;

@main-menu-item-hover-background: @color-primary-dark;
@main-menu-item-hover-text-color: @text-color-inverse-active;

@main-menu-item-active-hover-background: @color-primary-lighten;
@main-menu-item-active-hover-text-color: @text-color-inverse-active;


@main-menu-collpased-width: @main-menu-item-height;

/* #endregion */


/* #region mixins */
.gradient-linear(@color-start, @color-end) {
    background: @color-start;
    background: -moz-linear-gradient(left, @color-start 0%, @color-end 100%);
    background: -webkit-linear-gradient(left, @color-start 0%, @color-end 100%);
    background: linear-gradient(to right, @color-start 0%, @color-end 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='@color-start', endColorstr='@color-end',GradientType=1 );
}

.diagonal-lines-background(@border-color, @bg-color, @bg-lines-color) {
    border-color: @border-color;
    background-image: linear-gradient(-45deg, @bg-lines-color 25%, transparent 25%, transparent 50%, @bg-lines-color 50%, @bg-lines-color 75%, transparent 75%, @bg-color);
    background-size: 50px 50px;
    transition: background-image linear 100ms;
}

.page-block-header() {
    height: 42px;
    line-height: 42px;
    padding-left: 64px;
    background: @color-block-background;
    border: 1px solid @border-color-light;
    border-width: 1px 0 1px 0;
    font-weight: normal;
    font-size: 120%;
    margin-top: @block-interval-step*1.5;
}

.page-submenu(@width, @height) {
    float: left;
    width: @width;
    padding-top: @block-interval-step;
    padding-bottom: @block-interval-step;
    padding-left: @block-interval-step/2;
    box-sizing: border-box;
    height: @height;
    border-right: 1px solid @border-color-light;
    border-bottom: 1px solid @border-color-light;
    vertical-align: auto;

    .page-submenu-item {
        height: 32px;
        line-height: 32px;
        padding: 0 @block-interval-step 0 @block-interval-step*2;
        transition: background-color linear 100ms;

        .selected-marker {
            width: 12px;
            height: 12px;
            border-radius: 6px;
            background: @color-accent;
            display: inline-block;
            opacity: 0;
            position: relative;
            left: -8px;
            top: 1px;
        }

        &:hover {
            cursor: pointer;
            background: @color-primary-light-pale;
        }

        &.opened {
            background: @color-primary;
            color: @text-color-inverse-active;
            border-right: 1px solid @color-primary;
        }

        &.selected  .selected-marker {
            opacity: 1;
        }
    }
}

/* #endregion*/