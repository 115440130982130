@import '../../../global/variables.less';
@import '../../../global/forms.less';

@submenu-height: 36px;

.page-report-edit {

    @columns-selector-width: 35%;
    @columns-list-width: ~"calc(100% - @{columns-selector-width})";


    .table-constructor {
        position: relative;
        top: @block-interval-step;
        width: 100%;
        box-sizing: border-box;
        margin-bottom: @block-interval-step;

        .columns-selector {
            position: absolute;
            right: @columns-list-width;
            width: @columns-selector-width;
            height: 100%;
            overflow-y: auto;
            box-sizing: border-box;

            .ng-scrollbar-visible {
                padding: 0 2px !important;
            }
        }

        .columns-list {
            width: @columns-list-width;
            margin-left: @columns-selector-width;
            padding-left: @block-interval-step*3;
            box-sizing: border-box;
            min-height: 420px;

            .no-columns-selected {
                text-align: center;
                padding-top: 120px;

                .title {
                }
            }
        }
    }



    .submenu {
        margin: @block-interval-step*3 0 @block-interval-step 0;
        display: -webkit-flex;
        display: flex;
        flex-wrap: nowrap;
        height: @submenu-height;
        padding-left: 10%;
        padding-right: 25%;
        box-sizing: content-box;
        background: @color-block-background;
        border: 1px solid @border-color-light;

        .submenu-item {
            -webkit-flex: 1;
            -ms-flex: 1;
            flex: 1;
            box-sizing: border-box;
            position: relative;
            border: 2px solid transparent;
            transition: background-color linear 100ms;
            transition: border-color linear 100ms;
            height: 100%;

            > .text {
                color: @text-color-lighten;
                text-align: center;
                display: block;
                width: 100%;
                height: 100%;
                line-height: @submenu-height - 3px;
            }

            &:hover {
                background: @color-body-background;
                cursor: pointer;
            }

            &.selected {
                background: @color-body-background;
                border-color: @color-primary;
                height: @submenu-height + 8px;
                top: -4px;

                > .text {
                    position: relative;
                    top: 4px;
                }
            }
        }

        &:not(.readonly) {

            .type-selector-item:hover {
                opacity: 1;
                cursor: pointer;

                > .text {
                    color: @text-color-main;
                }
            }
        }
    }

    .no-schedule {
        margin-top: @block-interval-step * 2;
        padding-top: @block-interval-step * 2;
        text-align: center;
        box-sizing: border-box;
        height: 65px;
    }

    .email-recipients {
        padding-top: 4px;

        .input-text {
            margin: 3px 0 0 0;
        }

        .icon > * {
            height: @text-input-height-default;
            line-height: @text-input-height-default;
            font-size: 18px;
            width: @text-input-height-default;
            text-align: center;
            margin-top: 16px;
        }

        .icon > *:hover {
            cursor: pointer;
        }

        .icon-add {
            color: @color-primary-pale;
        }

        .icon-add:hover {
            color: @color-primary;
        }

        .icon-delete {
            color: @color-warning-default-pale;
        }

        .icon-delete:hover {
            color: @color-warning-default;
        }
    }

    checkbox.holiday .checkbox-component-outer-label {
        color: @color-warning-default;
    }

    .tabs {
        border: 1px solid @border-color-main;
        margin-top: 22px;
        margin-bottom: 8px;
        height: 40px;

        .tab {
            height: 38px;
            line-height: 38px;
        }
    }
}
