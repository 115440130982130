@import '../../variables.less';
@import '../../forms.less';

@input-text-label-valued-height: 14px;
@input-text-height: @text-input-height-default + @input-text-label-valued-height;


.label-valued() {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: @input-text-label-valued-height;
    opacity: 0;
    //transition: opacity linear 100ms;

    > span {
        display: inline-block;
        font-size: 10px;
        vertical-align: middle;
        position: absolute;
        top: @input-text-label-valued-height/2;
        left: 8px;
        height: @input-text-label-valued-height;
        width: auto;
        padding: 0 6px;
        background: @color-body-background;
        color: @text-color-lighten;
        transition: color linear 100ms;
    }

    &.visible {
        opacity: 1;
    }
}


.form-input-wrapper {
    display: block;
    height: @input-text-height;

    > input {
        display: block;
        margin: 0 !important;
        padding: 0 10px 0 10px !important;
        box-sizing: border-box;
        width: 100%;
        height: @text-input-height-default;
        line-height: @text-input-height-default;
        border: 1px solid @border-color-main !important;
        transition: border-color linear 100ms;
        color: @text-color-darken;

        &:hover {
            border: 1px solid @color-accent-light !important;
        }

        &:focus {
            border: 1px solid @color-accent !important;
        }

        &[readonly] {
            background: lighten(@color-block-background, 1%);
        }

        &[readonly]:hover,
        &[readonly]:focus {
            border: 1px solid @border-color-main !important;
        }

        &[readonly]:focus {
            &::-webkit-input-placeholder {
                color: @text-color-light;
            }

            &:-ms-input-placeholder {
                color: @text-color-light;
            }

            &::placeholder {
                color: @text-color-light;
            }
        }
    }
}


.input-text-length-counter {
    margin-top: @block-interval-step;
    padding-top: @form-input-label-default-height;
    display: inline-block;
    box-sizing: border-box;
    height: @text-input-height-default + @form-input-label-default-height;
    line-height: @text-input-height-default;
    padding-left: @block-interval-step / 2;
    color: @text-color-lighten;
}