@import '../../../../styles/global/variables.less';

@selector-height-unselected: 40px;
@selector-height-selected: 50px;
@selector-padding-top: @block-interval-step;


.banner-type-selector {
    height: @selector-height-selected + @selector-padding-top + 2px;
    padding-top: (@selector-height-selected - @selector-height-unselected) / 2 + @selector-padding-top;

    .banner-type-selector-inner {
        background: @color-block-background;
        box-sizing: border-box;
        border: 1px solid @border-color-main;
        height: @selector-height-unselected + 2px;

        .go-home {
            width: 40px;
            text-align: right;
            margin-right: @block-interval-step * 2;
            height: @selector-height-unselected;
            height: @selector-height-unselected;
            display: block;
            line-height: @selector-height-unselected - 2px;
            float: left;
            box-sizing: border-box;
            color: @text-color-lighten;

            * {
                height: @selector-height-unselected;
                line-height: @selector-height-unselected;
                display: inline-block;
            }

            .fa {
                line-height: @selector-height-unselected;
                font-size: 18px;
                height: @selector-height-unselected;
                color: @text-color-light;
                transition: color linear 500ms;
            }

            &.clickable {
                .fa {
                    color: @color-primary;
                }

                &:hover {
                    cursor: pointer;
                    color: @color-primary-dark;
                }
            }
        }


        .item {
            float: left;
            padding: 0 @block-interval-step*4;
            height: @selector-height-unselected;
            transition: background-color linear 200ms;
            display: block;
            line-height: @selector-height-unselected - 5px;
            color: @text-color-lighten;
            font-size: 16px;
            position: relative;
            top: 0;
            box-sizing: border-box;
            border: 2px solid transparent;
            transition: color linear 200ms;
            transition: background-color linear 200ms;
            transition: border-color linear 200ms;

            &:hover {
                cursor: pointer;
                color: @text-color-darken;
                background: @color-body-background; //@color-primary-light-pale;
            }

            &.selected {
                background: @color-body-background;
                height: @selector-height-selected;
                line-height: @selector-height-selected - 5px;
                top: -(@selector-height-selected - @selector-height-unselected)/2;
                border: 2px solid @color-primary;
                color: @text-color-darken;

                &:hover {
                    cursor: auto;
                    color: @text-color-darken;
                }
            }
        }
    }
}
