@import '../../../global/variables.less';

@targeting-body-height: 328px;
@targeting-footer-height: 48px;

@targeting-head-area-height: 32px;

.targeting-data-table-container {

    .data-table-container-header {
        height: @targeting-head-area-height;
        border-bottom: 1px solid @border-color-main;

        > div {
            float: left;
            height: @targeting-head-area-height - 2px;
            line-height: @targeting-head-area-height - 3px;
            font-weight: bolder;
        }
    }

    .data-table {
        border-bottom: 0;

        tr {
            td {
                height: 36px;
                line-height: 35px;
                box-sizing: border-box;
                padding: 0;
            }
        }
    }
}
