@import '../../variables.less';
@import '_base.less';


.input-text {
    margin: 4px 0;
    z-index: 100;

    .label-valued {
        .label-valued();
        z-index: 200;
    }

    .form-input-wrapper {
        > input {
            width: ~"calc(100% - 1px)";
            z-index: 150;
        }

        .eye-wrapper {
            display: block;
            width: 1px;
            height: 1px;
            position: relative;
            right: 0;
            top: 0;
            float: right;

            .eye {
                position: absolute;
                top: -@text-input-height-default + 1px;
                left: -@text-input-height-default - 1px;
                width: @text-input-height-default;
                height: @text-input-height-default - 2px;

                > i.fa {
                    width: @text-input-height-default - 2px;
                    height: @text-input-height-default - 2px;
                    line-height: @text-input-height-default - 2px;
                    text-align: center;
                    //font-size: 20px;
                    color: @text-color-light;
                }

                &.active > i.fa {
                    color: @color-accent-darken;
                }

                &:hover {
                    cursor: pointer;

                    > i.fa {
                        color: @color-accent;
                    }
                }

                &.active:hover > i.fa {
                    color: @color-accent-darken;
                }
            }
        }
    }


    &:focus {
        outline: none;
    }

    &.focused .label-valued > span {
        color: @color-accent;
    }

    &.focused.readonly .label-valued > span {
        color: @text-color-lighten;
    }

    &.no-label {
        margin: 0;

        .label-valued {
            display: none;
        }
    }

    &.multiline {
        height: auto;

        .form-input-wrapper {
            height: auto;

            > textarea {
                width: 100%;
                height: 82px;
                margin-top: 0;
                box-sizing: border-box;
                padding: 10px;
                resize: none;
            }
        }
    }

    &.with-symbols-counter {
        .form-input-wrapper {
            margin-right: 100px;
        }

        .symbols-counter-wrapper {
            float: right;
            width: 100px;
            height: @text-input-height-default;
            line-height: @text-input-height-default;
            color: @text-color-lighten;
        }
    }
}
