@import '../variables.less';
@import 'forms/_base.less';

@file-loader-height: 44px;
@file-loader-v-padding: @block-interval-step;

.file-loader-wrapper {

    .label-valued {
        .label-valued();
    }

    .file-loader {
        height: @file-loader-height + 2*@file-loader-v-padding;
        box-sizing: border-box;
        background: @color-block-background;
        padding: @file-loader-v-padding @block-interval-step;
        border-radius: 6px;

        input[type="file"] {
            display: none;
        }

        > .info-wrapper {
            margin-right: 140px;
            height: 100%;

            > .label, > .filename {
                height: 100%;
                float: left;
                width: 50%;
                padding-left: @block-interval-step;
            }

            > .validation-messages {
                display: table-cell;
                height: @file-loader-height;
                width: 100%;
                vertical-align: middle;

                > .validation-messages-item {
                    font-size: 12px;
                    color: @text-color-light;
                }
            }

            > .label {
                line-height: @file-loader-height;
            }

            > .filename {
                line-height: @file-loader-height;
            }

            > .error {
            }
        }

        > .button-wrapper {
            float: right;
            width: 140px;
            height: 100%;
            text-align: right;
        }
    }
}
