@import '../../../global/variables.less';

.page-campaigns-list {
    .campaign-type-icon {
        border-bottom: 1px dotted @border-color-dark;
        color: @text-color-main;
        padding: 0 4px;
        height: 14px;
        line-height: 12px;
        box-sizing: border-box;
        margin-top: 4px;
        display: inline-block;

        &:hover{
            cursor: help;
        }
    }
}