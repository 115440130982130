@import '../../variables.less';

@pager-height: 40px;
@pager-page-width: 40px;

.dt-pager {
    padding: 0 @block-interval-step * 2;
    list-style-type: none;
    display: block;
    height: @pager-height;
    margin: @block-interval-step 0;
    /*
    .pager-per-page-text {
        float: right;
        width: 120px;
        text-align: right;
        height: @pager-height;
        line-height: @pager-height - 4px;
    }
        */
    .pager-per-page-dropdown {
        float: right;
        display: inline-block;
        width: 60px;
        vertical-align: bottom;
    }


    > ul {
        display: block;
        //padding-inline-start: unset;
        margin: 0;
        padding: 0;
        margin-right: 60px;

        li {
            display: inline-block;
            height: @pager-height;
            width: @pager-page-width;

            > span {
                color: @text-color-lighten;
                display: inline-block;
                width: 100%;
                height: 100%;
                line-height: @pager-height;
                text-align: center;
                text-decoration: none;

                &:hover {
                    color: @color-primary-lighten;
                }

                &.pager-page-current {
                    background-color: @background-control-active;
                    color: @color-primary-dark;
                }
            }

            > span:hover {
                cursor: pointer;
                color: @text-color-darken;
            }

            > span.pager-page-current {
                background: @color-accent-light;
                cursor: default;
                border-radius: 3px;
            }
        }
    }
}
