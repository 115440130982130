@import '../../variables.less';
@import '_variables.less';

.table-header-text-base() {
    white-space: nowrap;
    line-height: @head-row-height;
    font-weight: bolder;
}


.data-table-sticky-header-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;

    .data-table-sticky-header-left {
        position: fixed;
        left: 0;
        top: 0;
        width: @layout-padding;
        height: 35px;
        background: @color-block-background;
        border-right: 1px solid @border-color-main;
        z-index: 100;
    }

    .data-table-sticky-header-right {
        position: fixed;
        right: 0;
        width: @layout-padding - 2px;
        top: 0;
        height: 35px;
        background: @color-block-background;
        border-left: 1px solid @border-color-main;
        z-index: 100;
    }


    .data-table-sticky-header-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        height: 35px;
        overflow: hidden;
        border-bottom: 1px solid @border-color-main;
        -webkit-box-shadow: 0px 0px 73px 1px @color-shadow;
        -moz-box-shadow: 0px 0px 73px 1px @color-shadow;
        box-shadow: 0px 0px 73px 1px @color-shadow;
        border-bottom: 1px solid @border-color-main;

        .data-table-sticky-header {
            position: relative;
            height: 35px;
            padding: 0 @layout-padding;
            background: @color-block-background;
            width: 10000px;

            .header-text {
                .table-header-text-base();
                box-sizing: border-box;
                display: inline-block;
                vertical-align: middle;
            }

            .header-setup {
                box-sizing: border-box;
                display: inline-block;
                vertical-align: middle;
            }
        }
    }
}


.data-table-container {
    width: 100%;
    margin-bottom: @block-interval-step;
}


.data-table {
    width: 100%;
    border-collapse: collapse;
    border-bottom: 1px solid @border-color-main;

    thead {
        th:first-child {
            padding-left: 30px;
        }

        th:last-child {
            padding-right: 20px;
        }

        th {
            text-align: left;
            line-height: @head-row-height;
            height: @head-row-height;
            border-bottom: 1px solid @border-color-main;
            padding: 0 7px;
        }

        th.header-text {
            .table-header-text-base();
            width: 32px;
        }
    }

    tbody {
        tr {
            td {
                text-align: left;
                padding: 10px 7px;
                color: @text-color-lighten;

                &:first-child {
                    padding-left: 30px;
                }

                &:last-child {
                    padding-right: 20px;
                }

                &.text-content {
                    max-width: 250px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                &.min-width {
                    width: 1px;
                    overflow: visible !important;
                }
            }

            &:nth-child(even) {
                background: @color-block-background;
            }

            &.sub-row {
                td:first-child {
                    padding-left: 40px;
                }
            }

            &.deleted {
                td {
                    color: @text-color-lighten;
                }
            }
        }
    }

    tfoot {
        td {
            padding-left: 10px;
            padding: 10px;
            border-top: 1px solid @border-color-main;
            color: @text-color-darken;

            &:first-child {
                padding-left: 30px;
            }
        }
    }
}

.header-setup {
    white-space: nowrap;
    padding-left: 15px !important;
    text-align: left;

    .sorting-arrow {
        font-size: 12px;
        line-height: 24px;
        font-weight: normal;
        cursor: pointer;
        padding: 0 8px;
        border-radius: 3px;
        color: @color-accent;

        .order {
            margin-left: 4px;
            font-family: @font-family-default;
        }
    }

    .sorting-arrow:hover {
        background: @color-accent;
        color: @color-block-background;
    }

    .sorting-arrow-selected {
        color: @color-accent;
    }
}