@import 'variables.less';

@label-width: 20%;
@label-margin-right: 30px;
@form-group-margin-bottom: 22px;

@form-input-label-default-height: @block-interval-step * 2;


.form-input-label-default {
    display: block;
    height: @form-input-label-default-height;
    line-height: @form-input-label-default-height;
    color: @text-color-main;
    font-size: @text-size-small;
    text-transform: uppercase;
    white-space: nowrap;
}

.optional-label {
    height: 12px;
    line-height: 12px;
    font-size: 10px;
    color: @color-primary-lighten;
    //margin-top: 4px;

    > span {
        font-size: 12px;
        text-transform: lowercase;
    }
}

textarea {
    width: 100%;
    padding: 6px;
    box-sizing: border-box;
    color: @text-color-lighten;
    border: 1px solid @border-color-main;
}

textarea:focus, textarea:active {
    border-color: @border-control-active;
    color: @text-color-main;
}


.form {
    .form-title {
        height: 20px;
        line-height: 20px;
        font-size: 16px;
        padding-left: 40px;
        margin-bottom: 40px;
    }

    .form-group {
        margin-bottom: 12px;
        text-align: left;
    }

    select {
        background-color: #fff;
    }
}

.checkbox-component {
    margin-top: 10px;
}

textarea {
    width: 60%;
    margin-top: 15px;
}

input[type=text],
input[type=email],
input[type=tel],
input[type=password] {
    border: 1px solid @border-color-main;
    font-family: @font-family-default;
}

input[type=text]:focus,
input[type=email]:focus,
input[type=tel]:focus,
input[type=password]:focus {
    border: 1px solid @color-accent;
}

input[type=text]::-webkit-input-placeholder,
input[type=email]::-webkit-input-placeholder,
input[type=tel]::-webkit-input-placeholder,
input[type=password]::-webkit-input-placeholder {
    color: @text-color-light;
}

input[type=text]::-moz-placeholder,
input[type=email]::-moz-placeholder,
input[type=tel]::-moz-placeholder,
input[type=password]::-moz-placeholder {
    color: @text-color-light;
    opacity: 1;
}

input[type=text]:-moz-placeholder,
input[type=email]:-moz-placeholder,
input[type=tel]:-moz-placeholder,
input[type=password]:-moz-placeholder {
    color: @text-color-light;
    opacity: 1;
}

input[type=text]:-ms-input-placeholder,
input[type=email]:-ms-input-placeholder,
input[type=tel]:-ms-input-placeholder,
input[type=password]:-ms-input-placeholder {
    color: @text-color-light;
}

input[type=text]:focus::-webkit-input-placeholder,
input[type=email]:focus::-webkit-input-placeholder,
input[type=tel]:focus::-webkit-input-placeholder,
input[type=password]:focus::-webkit-input-placeholder {
    color: @text-color-lighten;
}

input[type=text]:focus::-moz-placeholder,
input[type=email]:focus::-moz-placeholder,
input[type=tel]:focus::-moz-placeholder,
input[type=password]:focus::-moz-placeholder {
    color: @text-color-lighten;
    opacity: 1;
}

input[type=text]:focus:-moz-placeholder,
input[type=email]:focus:-moz-placeholder,
input[type=tel]:focus:-moz-placeholder,
input[type=password]:focus:-moz-placeholder {
    color: @text-color-lighten;
    opacity: 1;
}

input[type=text]:focus:-ms-input-placeholder,
input[type=email]:focus:-ms-input-placeholder,
input[type=tel]:focus:-ms-input-placeholder,
input[type=password]:focus:-ms-input-placeholder {
    color: @text-color-lighten;
}


.form-group-help {
    font-size: 12px;
    color: @text-color-light;
}

.input-help-hint {
    color: @text-color-lighten;
    background: @color-info-lighten;
    border-radius: 3px;
    padding: 8px 8px;
}

.form-group.to-datepicker {
    flex-basis: 350px;

    date-picker {
        display: inline-block;
        width: 180px;

        .datepicker-actions__button {
            display: none;
        }

        input {
            width: 100% !important;
        }
    }
}

.alert {
    margin-left: ~"calc(20% + 25px)";
}

.group-submit {
    margin-top: 40px;
}
