@import '../variables.less';

@triangle-width: 10px;
@message-block-width: 300px;
@message-height: @main-menu-height - 8px + 32px;

.messages-container {
    position: relative;

    .messages-wrapper {
        position: absolute;
        width: @message-block-width;
        height: @message-height;
        top: @header-height + 2px;
        left: -(@profile-block-width / 2);
        background: white;
        //border: 1px solid @border-color-main;
        //border-width: 1px 1px 0 1px;
        color: @text-color-main;
        -webkit-box-shadow: 0px 0px 12px 3px @color-shadow;
        -moz-box-shadow: 0px 0px 12px 3px @color-shadow;
        box-shadow: 0px 0px 12px 3px @color-shadow;
        z-index: 2000;

        .message {
            padding: 4px 8px;
            width: @message-block-width;
            height: @message-height;
            box-sizing: border-box;

            .title {
                //background: @color-success-lighten;
            }

            .text {
                margin-top: 6px;
                font-size: @text-size-small;
            }
        }
    }

    .messages-wrapper:after /*,
    .messages-wrapper:before*/ {
        content: '';
        display: block;
        position: absolute;
        top: 100%;
        width: 0;
        height: 0;
        border-style: solid;
    }

    .messages-wrapper:after {
        top: -20px;
        left: ~"calc(100% - 30px)";
        border-color: transparent transparent white transparent;
        border-width: 10px;
    }
    /*
    .messages-wrapper:before {
        top: -22px;
        left: ~"calc(100% - 31px)";
        border-color: transparent transparent @border-color-main transparent;
        border-width: 11px;
    }
    */
    .messages-wrapper.success {
        .message {
            background: @color-success-default;
            color: white;
        }
    }

    .messages-wrapper.info {
        .message {
            background: @color-info-default;
        }
    }

    .messages-wrapper.error {
        .message {
            background: @color-warning-default;
            color: white;
        }
    }




    .messages-wrapper.success:after {
        border-bottom-color: @color-success-default;
    }

    .messages-wrapper.info:after {
        border-bottom-color: @color-info-default;
    }

    .messages-wrapper.error:after {
        border-bottom-color: @color-warning-default;
    }
}


