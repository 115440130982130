@import '../variables.less';

@popup-header-height: 40px;
@popup-footer-height: 60px;
@popup-small-height: 240px;
@popup-default-height: 420px;
@popup-large-height: 660px;


.popup-content-height(@height) {
    min-height: @height;

    .popup-content {
        min-height: @height;
    }

    &.with-header {
        min-height: @height - @popup-header-height;

        .popup-content {
            min-height: @height - @popup-header-height;
        }
    }

    &.with-footer {
        min-height: @height - @popup-footer-height;

        .popup-content {
            min-height: @height - @popup-footer-height;
        }
    }

    &.with-header.with-footer {
        min-height: @height - @popup-footer-height - @popup-header-height;

        .popup-content {
            min-height: @height - @popup-footer-height - @popup-header-height;
        }
    }
}


.popup {
    position: absolute;
    z-index: 99996;
    top: 120px;
    left: 25%;
    width: 50%;
    border: 1px solid @border-color-dark;
    background: white;
    min-height: @popup-default-height;
    -webkit-box-shadow: 0px 0px 34px 1px @border-color-main;
    -moz-box-shadow: 0px 0px 34px 1px @border-color-main;
    box-shadow: 0px 0px 34px 1px @border-color-main;
    border-radius: 2px;

    .popup-header {
        height: @popup-header-height;
        background: @color-block-background;
        border-bottom: 1px solid @border-color-light;

        .popup-header-buttons {
            height: @popup-header-height;
            width: 40px;
            float: right;
            line-height: @popup-header-height;
            text-align: center;
            font-size: 18px;
        }

        .popup-header-text {
            margin-right: 50px;
            height: @popup-header-height;
            line-height: @popup-header-height;
            padding-left: 16px;
            font-size: 18px;
            font-weight: bolder;
            color: @text-color-lighten;
        }
    }

    .popup-content {
        padding: 10px 20px;
        overflow: visible;
    }

    .popup-footer {
        text-align: right;
        padding-right: 20px;
        height: @popup-footer-height;
        position: relative;
        top: 0;
        left: 0;

        .error-message {
            position: absolute;
            bottom: 10px;
            left: 10px;
            width: 420px;
            height: @popup-footer-height - 10px;
            border: 1px solid @color-warning-default;
            background: @color-warning-lighten;
            border-radius: 3px;
            padding: 3px 6px;
            text-align: left;
        }
    }

    .popup-content-height(@popup-default-height);
}


.wide .popup {
    width: 80%;
    left: 10%;
}

.large .popup {
    width: 80%;
    left: 10%;
    .popup-content-height(@popup-large-height);
}

.long .popup {
    .popup-content-height(@popup-large-height);
}

.small .popup {
    width: 40%;
    left: 30%;
    .popup-content-height(@popup-small-height);
}
