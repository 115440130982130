@import '../global/variables.less';



html {
    height: 100%;
    min-height: 100%;
}

body.app {
    background: @color-body-background;
}

app-root, app-layout, .layout {
    height: 100%;
    min-height: 100%;
}

.layout {
    z-index: 10;

    .main-menu {
        float: left;
        width: @main-menu-width;
        top: 0;
        left: 0;
        height: 100vh;
        position: fixed;
        z-index: 30;
    }

    .page {
        margin-left: @main-menu-width;
        z-index: 20;

        .page-content {
            position: relative;
            height: ~"calc(100vh - @{header-height})";
        }
    }

    .page.menu-collapsed {
        margin-left: @main-menu-collpased-width;

        .app-header {
            left: @main-menu-collpased-width;
            width: ~"calc(100% - @{main-menu-collpased-width})";
        }
    }
}


.main-menu {
    background: @main-menu-background;
    transition: width linear 100ms;

    nav {
        width: 100%;
        height: 100%;

        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            height: 100%;

            li:not(.logo-item) {
                width: @main-menu-width;
                display: block;
                height: @main-menu-item-height;
                box-sizing: border-box;
                font-size: @main-menu-font-size;

                a {
                    width: @main-menu-width;
                    transition: width linear 50ms;
                    display: block;
                    box-sizing: border-box;
                    height: @main-menu-item-height;
                    text-decoration: none;
                    vertical-align: middle;
                    transition: background-color linear 100ms;
                    color: @main-menu-item-text-color;
                    border-bottom: 1px solid @main-menu-item-border-color;

                    * {
                        line-height: @main-menu-item-height;
                    }

                    .fa {
                        display: inline-block;
                        width: @main-menu-width-collapsed;
                        margin: 0;
                        padding: 0;
                        text-align: center;
                        font-size: 24px;
                    }

                    span {
                        display: inline-block;
                        vertical-align: top;
                    }
                }

                a:focus {
                    outline: none;
                }

                a:hover {
                    background-color: @main-menu-item-hover-background;
                    color: @main-menu-item-hover-text-color;
                }

                a.active {
                    background: @main-menu-item-active-background;
                    color: @main-menu-item-active-text-color;
                }

                a.active:hover {
                    background: @main-menu-item-active-hover-background;
                    color: @main-menu-item-active-hover-text-color;
                }
            }

            li.logo-item {
                width: 100%;
                display: block;
                height: @main-menu-item-height;
                border-bottom: 1px solid @main-menu-item-border-color;
                box-sizing: border-box;
                padding-top: @main-menu-item-height / 5.1;
                color: white;

                .str-1, .str-2 {
                    display: block;
                    width: 100%;
                    text-align: center;
                    height: @main-menu-item-height / 2.8 !important;
                    line-height: @main-menu-item-height / 2.8 !important;
                    font-weight: bolder;
                    color: @main-menu-item-text-color;
                }

                .str-1 {
                    font-size: 18px;
                }

                .str-2 {
                    font-size: 11px;
                    text-transform: uppercase;
                }

                a:hover {
                    text-decoration: none;
                }
            }
        }
    }

    &.collapsed {
        width: @main-menu-collpased-width;

        nav {
            width: @main-menu-collpased-width;

            ul {
                li {
                    width: @main-menu-collpased-width;

                    a {
                        width: @main-menu-collpased-width;

                        span {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}



.app-header {
    height: @header-height;
    border-bottom: 1px solid @border-color-main;
    box-sizing: border-box;
    top: 0;
    left: @main-menu-width;
    width: ~"calc(100% - @{main-menu-width})";
    display: block;
    position: fixed;
    background: @color-body-background;
    z-index: 1000;

    .header-item {
        display: inline-block;
        height: @header-height;
    }

    .header-item-left {
        float: left;
        text-align: left;
    }

    .header-item-right {
        float: right;
        text-align: right;
        margin-right: 18px;
    }

    .main-menu-toggle {
        width: @header-height;
        height: @header-height;
        line-height: @header-height;
        text-align: center;

        .fa {
            width: @header-height;
            height: @header-height;
            line-height: @header-height;
            color: @main-menu-background;
            font-size: 18px;
        }

        .fa:hover {
            cursor: pointer;
            color: @main-menu-item-hover-background;
        }
    }

    .page-title {
        .single-item {
            line-height: @header-height - 4px;
            font-size: 140%;
        }

        .breadcrumb {
            .breadcrumb-item {
                .link {
                    display: inline-block;
                    height: 14px;
                    line-height: 14px;
                    font-size: @text-size-small;
                    margin-top: 8px;
                    margin-bottom: 6px;

                    a {
                        color: @text-color-lighten;
                    }

                    .arrow {
                        margin: 0 8px;
                        font-size: @text-size-extra-small;
                        color: @text-color-light;
                    }
                }

                .current-page-header {
                    display: block;
                    clear: left;
                    font-size: @text-size-h3;
                }
            }
        }
    }

    .account-info {
        width: 92px;
        height: 100%;
        margin: 0;

        account-info {
            height: 100%;
        }

        .profile-menu-wrapper {
            width: 100%;
            height: 100%;

            .profile-submenu-hover-target {
                width: 100%;
                height: 100%;

                .balance {
                    color: @text-color-lighten;
                    width: 100%;
                    padding-top: 6px;
                    height: ~"calc(50% - 1px)";
                    text-align: center;
                    box-sizing: border-box;
                    display: block;

                    .currency-symbol {
                        font-size: 12px;
                        display: inline-block;
                        margin-right: 4px;
                    }

                    .balance-value {
                        font-size: 12px;
                    }
                }

                .icon-wrapper {
                    display: block;
                    width: 100%;
                    height: 50%;
                    text-align: center;
                    box-sizing: border-box;

                    > a {
                        .fa {
                            font-size: 18px;
                            height: ~"calc(100% - 4px)";
                            color: @text-color-lighten;
                        }
                    }

                    &.no-balance {
                        height: 100%;
                        padding-top: 20px;
                    }


                    .profile-submenu-wrapper {
                        width: 100%;
                        height: 1px;
                        position: relative;
                        top: 0;
                        left: 0;
                        cursor: default;

                        .profile-submenu {
                            display: none;
                            position: absolute;
                            text-align: left;
                            top: 0;
                            left: -240px + 92px;
                            width: 240px;
                            height: auto;
                            background: @color-body-background;
                            box-sizing: border-box;
                            border: 1px solid @border-color-main;
                            border-top-color: @border-color-light;
                            border-right: 0;

                            .name {
                                display: block;
                                line-height: 16px;
                                padding: 12px 4px 2px 16px;
                                background: @color-block-background;
                                cursor: text;
                            }

                            .role {
                                display: block;
                                font-size: 12px;
                                line-height: 16px;
                                padding: 2px 4px 15px 16px;
                                color: @text-color-lighten;
                                margin-bottom: @block-interval-step;
                                background: @color-block-background;
                                cursor: text;
                            }

                            .profile-submenu-items-list {
                                margin: 0;
                                padding: 0;
                                list-style-type: none;

                                .profile-submenu-item {

                                    @_item_height: 36px;

                                    > a {
                                        display: inline-block;
                                        width: 100%;
                                        box-sizing: border-box;
                                        padding-left: 16px;
                                        height: @_item_height;
                                        color: @text-color-main;
                                        background: @color-body-background;
                                        transition: background-color linear 100ms;

                                        .profile-submenu-item-icon {
                                            display: inline-block;
                                            box-sizing: border-box;
                                            width: @_item_height;
                                            height: @_item_height;
                                            line-height: @_item_height;
                                            margin-right: 4px;
                                        }

                                        .profile-submenu-item-link {
                                            line-height: @_item_height;
                                        }

                                        &:hover {
                                            cursor: pointer;
                                            text-decoration: none;
                                            background: @color-block-background;

                                            .profile-submenu-item-icon {
                                                color: @color-accent-darken;
                                            }

                                            .profile-submenu-item-link {
                                                color: @color-primary-dark;
                                            }
                                        }
                                    }


                                    &.logout {
                                        border-top: 1px solid @border-color-light;
                                        height: @_item_height * 1.5;

                                        > a {
                                            height: @_item_height * 1.5;

                                            .profile-submenu-item-icon {
                                                height: @_item_height * 1.5;
                                                line-height: @_item_height * 1.5;
                                            }

                                            .profile-submenu-item-link {
                                                line-height: @_item_height * 1.5;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &:hover {
                        > a {
                            .fa {
                                cursor: pointer;
                                text-decoration: none;
                                color: @color-accent;
                            }
                        }

                        .profile-submenu-wrapper {
                            .profile-submenu {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }


    @two-level-item-height: 19px;

    .search {
        padding-top: (@header-height - 2 * @two-level-item-height) / 2;
        box-sizing: border-box;
        padding-left: 10px;
        margin-right: 20px;
        min-width: 380px;
    }
}



.page-content {
    top: @header-height;
}


.block-default {
    padding: @block-interval-step @block-interval-step @block-interval-step @block-interval-step*2;

    > div.header {
        height: 48px;
        line-height: 48px;
        border-bottom: 1px solid @border-color-light;
        margin-bottom: 12px;
        font-size: 16px;
    }
}


.block-accent {
    padding: @block-interval-step @block-interval-step @block-interval-step @block-interval-step*2;
    background: @color-block-background;

    > div.header {
        height: 48px;
        border-bottom: 1px solid @border-color-main;
        margin-bottom: 12px;

        .title {
            width: 50%;
            height: 48px;
            float: left;
            font-size: 16px;
            line-height: 48px;
        }

        .actions {
            margin-left: 50%;
            text-align: right;
        }
    }
}




/* #region media */


@media screen and (max-width: 1200px) {
    .app-header .search {
        min-width: 240px;
    }
}

/* #endregion */
