@font-face {
  font-family: 'Roboto Condensed';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local('Roboto Condensed Light Italic'), local('RobotoCondensed-LightItalic'), url(https://fonts.gstatic.com/s/robotocondensed/v18/ieVg2ZhZI2eCN5jzbjEETS9weq8-19eDpCEYag.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Condensed';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Roboto Condensed Italic'), local('RobotoCondensed-Italic'), url(https://fonts.gstatic.com/s/robotocondensed/v18/ieVj2ZhZI2eCN5jzbjEETS9weq8-19e7CA.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Condensed';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local('Roboto Condensed Bold Italic'), local('RobotoCondensed-BoldItalic'), url(https://fonts.gstatic.com/s/robotocondensed/v18/ieVg2ZhZI2eCN5jzbjEETS9weq8-19eDtCYYag.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Roboto Condensed Light'), local('RobotoCondensed-Light'), url(https://fonts.gstatic.com/s/robotocondensed/v18/ieVi2ZhZI2eCN5jzbjEETS9weq8-33mZKCM.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Roboto Condensed'), local('RobotoCondensed-Regular'), url(https://fonts.gstatic.com/s/robotocondensed/v18/ieVl2ZhZI2eCN5jzbjEETS9weq8-59U.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Roboto Condensed Bold'), local('RobotoCondensed-Bold'), url(https://fonts.gstatic.com/s/robotocondensed/v18/ieVi2ZhZI2eCN5jzbjEETS9weq8-32meKCM.ttf) format('truetype');
}
/* #region media */
/* #endregion */
/* #region texts */
/* #endregion */
/* #region colors */
/*
    use to custom elements only
    not to standart browser inputs
*/
/* #endregion */
/* #region layout */
/* #endregion */
/* #region mixins */
/* #endregion*/
html {
  height: 100%;
  min-height: 100%;
}
body.app {
  background: white;
}
app-root,
app-layout,
.layout {
  height: 100%;
  min-height: 100%;
}
.layout {
  z-index: 10;
}
.layout .main-menu {
  float: left;
  width: 196px;
  top: 0;
  left: 0;
  height: 100vh;
  position: fixed;
  z-index: 30;
}
.layout .page {
  margin-left: 196px;
  z-index: 20;
}
.layout .page .page-content {
  position: relative;
  height: calc(100vh - 64px);
}
.layout .page.menu-collapsed {
  margin-left: 64px;
}
.layout .page.menu-collapsed .app-header {
  left: 64px;
  width: calc(100% - 64px);
}
.main-menu {
  background: #444444;
  transition: width linear 100ms;
}
.main-menu nav {
  width: 100%;
  height: 100%;
}
.main-menu nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  height: 100%;
}
.main-menu nav ul li:not(.logo-item) {
  width: 196px;
  display: block;
  height: 64px;
  box-sizing: border-box;
  font-size: 14px;
}
.main-menu nav ul li:not(.logo-item) a {
  width: 196px;
  transition: width linear 50ms;
  display: block;
  box-sizing: border-box;
  height: 64px;
  text-decoration: none;
  vertical-align: middle;
  transition: background-color linear 100ms;
  color: #e9e9e9;
  border-bottom: 1px solid #565656;
}
.main-menu nav ul li:not(.logo-item) a * {
  line-height: 64px;
}
.main-menu nav ul li:not(.logo-item) a .fa {
  display: inline-block;
  width: 64px;
  margin: 0;
  padding: 0;
  text-align: center;
  font-size: 24px;
}
.main-menu nav ul li:not(.logo-item) a span {
  display: inline-block;
  vertical-align: top;
}
.main-menu nav ul li:not(.logo-item) a:focus {
  outline: none;
}
.main-menu nav ul li:not(.logo-item) a:hover {
  background-color: #5e547c;
  color: white;
}
.main-menu nav ul li:not(.logo-item) a.active {
  background: #8676b6;
  color: white;
}
.main-menu nav ul li:not(.logo-item) a.active:hover {
  background: #9986d3;
  color: white;
}
.main-menu nav ul li.logo-item {
  width: 100%;
  display: block;
  height: 64px;
  border-bottom: 1px solid #565656;
  box-sizing: border-box;
  padding-top: 12.54901961px;
  color: white;
}
.main-menu nav ul li.logo-item .str-1,
.main-menu nav ul li.logo-item .str-2 {
  display: block;
  width: 100%;
  text-align: center;
  height: 22.85714286px !important;
  line-height: 22.85714286px !important;
  font-weight: bolder;
  color: #e9e9e9;
}
.main-menu nav ul li.logo-item .str-1 {
  font-size: 18px;
}
.main-menu nav ul li.logo-item .str-2 {
  font-size: 11px;
  text-transform: uppercase;
}
.main-menu nav ul li.logo-item a:hover {
  text-decoration: none;
}
.main-menu.collapsed {
  width: 64px;
}
.main-menu.collapsed nav {
  width: 64px;
}
.main-menu.collapsed nav ul li {
  width: 64px;
}
.main-menu.collapsed nav ul li a {
  width: 64px;
}
.main-menu.collapsed nav ul li a span {
  display: none;
}
.app-header {
  height: 64px;
  border-bottom: 1px solid #dddddd;
  box-sizing: border-box;
  top: 0;
  left: 196px;
  width: calc(100% - 196px);
  display: block;
  position: fixed;
  background: white;
  z-index: 1000;
}
.app-header .header-item {
  display: inline-block;
  height: 64px;
}
.app-header .header-item-left {
  float: left;
  text-align: left;
}
.app-header .header-item-right {
  float: right;
  text-align: right;
  margin-right: 18px;
}
.app-header .main-menu-toggle {
  width: 64px;
  height: 64px;
  line-height: 64px;
  text-align: center;
}
.app-header .main-menu-toggle .fa {
  width: 64px;
  height: 64px;
  line-height: 64px;
  color: #444444;
  font-size: 18px;
}
.app-header .main-menu-toggle .fa:hover {
  cursor: pointer;
  color: #5e547c;
}
.app-header .page-title .single-item {
  line-height: 60px;
  font-size: 140%;
}
.app-header .page-title .breadcrumb .breadcrumb-item .link {
  display: inline-block;
  height: 14px;
  line-height: 14px;
  font-size: 0.86rem;
  margin-top: 8px;
  margin-bottom: 6px;
}
.app-header .page-title .breadcrumb .breadcrumb-item .link a {
  color: #808080;
}
.app-header .page-title .breadcrumb .breadcrumb-item .link .arrow {
  margin: 0 8px;
  font-size: 0.714rem;
  color: #B8C7D6;
}
.app-header .page-title .breadcrumb .breadcrumb-item .current-page-header {
  display: block;
  clear: left;
  font-size: 1.286rem;
}
.app-header .account-info {
  width: 92px;
  height: 100%;
  margin: 0;
}
.app-header .account-info account-info {
  height: 100%;
}
.app-header .account-info .profile-menu-wrapper {
  width: 100%;
  height: 100%;
}
.app-header .account-info .profile-menu-wrapper .profile-submenu-hover-target {
  width: 100%;
  height: 100%;
}
.app-header .account-info .profile-menu-wrapper .profile-submenu-hover-target .balance {
  color: #808080;
  width: 100%;
  padding-top: 6px;
  height: calc(50% - 1px);
  text-align: center;
  box-sizing: border-box;
  display: block;
}
.app-header .account-info .profile-menu-wrapper .profile-submenu-hover-target .balance .currency-symbol {
  font-size: 12px;
  display: inline-block;
  margin-right: 4px;
}
.app-header .account-info .profile-menu-wrapper .profile-submenu-hover-target .balance .balance-value {
  font-size: 12px;
}
.app-header .account-info .profile-menu-wrapper .profile-submenu-hover-target .icon-wrapper {
  display: block;
  width: 100%;
  height: 50%;
  text-align: center;
  box-sizing: border-box;
}
.app-header .account-info .profile-menu-wrapper .profile-submenu-hover-target .icon-wrapper > a .fa {
  font-size: 18px;
  height: calc(100% - 4px);
  color: #808080;
}
.app-header .account-info .profile-menu-wrapper .profile-submenu-hover-target .icon-wrapper.no-balance {
  height: 100%;
  padding-top: 20px;
}
.app-header .account-info .profile-menu-wrapper .profile-submenu-hover-target .icon-wrapper .profile-submenu-wrapper {
  width: 100%;
  height: 1px;
  position: relative;
  top: 0;
  left: 0;
  cursor: default;
}
.app-header .account-info .profile-menu-wrapper .profile-submenu-hover-target .icon-wrapper .profile-submenu-wrapper .profile-submenu {
  display: none;
  position: absolute;
  text-align: left;
  top: 0;
  left: -148px;
  width: 240px;
  height: auto;
  background: white;
  box-sizing: border-box;
  border: 1px solid #dddddd;
  border-top-color: #f0f0f0;
  border-right: 0;
}
.app-header .account-info .profile-menu-wrapper .profile-submenu-hover-target .icon-wrapper .profile-submenu-wrapper .profile-submenu .name {
  display: block;
  line-height: 16px;
  padding: 12px 4px 2px 16px;
  background: #f9f9f9;
  cursor: text;
}
.app-header .account-info .profile-menu-wrapper .profile-submenu-hover-target .icon-wrapper .profile-submenu-wrapper .profile-submenu .role {
  display: block;
  font-size: 12px;
  line-height: 16px;
  padding: 2px 4px 15px 16px;
  color: #808080;
  margin-bottom: 12px;
  background: #f9f9f9;
  cursor: text;
}
.app-header .account-info .profile-menu-wrapper .profile-submenu-hover-target .icon-wrapper .profile-submenu-wrapper .profile-submenu .profile-submenu-items-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.app-header .account-info .profile-menu-wrapper .profile-submenu-hover-target .icon-wrapper .profile-submenu-wrapper .profile-submenu .profile-submenu-items-list .profile-submenu-item > a {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  padding-left: 16px;
  height: 36px;
  color: #435261;
  background: white;
  transition: background-color linear 100ms;
}
.app-header .account-info .profile-menu-wrapper .profile-submenu-hover-target .icon-wrapper .profile-submenu-wrapper .profile-submenu .profile-submenu-items-list .profile-submenu-item > a .profile-submenu-item-icon {
  display: inline-block;
  box-sizing: border-box;
  width: 36px;
  height: 36px;
  line-height: 36px;
  margin-right: 4px;
}
.app-header .account-info .profile-menu-wrapper .profile-submenu-hover-target .icon-wrapper .profile-submenu-wrapper .profile-submenu .profile-submenu-items-list .profile-submenu-item > a .profile-submenu-item-link {
  line-height: 36px;
}
.app-header .account-info .profile-menu-wrapper .profile-submenu-hover-target .icon-wrapper .profile-submenu-wrapper .profile-submenu .profile-submenu-items-list .profile-submenu-item > a:hover {
  cursor: pointer;
  text-decoration: none;
  background: #f9f9f9;
}
.app-header .account-info .profile-menu-wrapper .profile-submenu-hover-target .icon-wrapper .profile-submenu-wrapper .profile-submenu .profile-submenu-items-list .profile-submenu-item > a:hover .profile-submenu-item-icon {
  color: #e47f44;
}
.app-header .account-info .profile-menu-wrapper .profile-submenu-hover-target .icon-wrapper .profile-submenu-wrapper .profile-submenu .profile-submenu-items-list .profile-submenu-item > a:hover .profile-submenu-item-link {
  color: #5e547c;
}
.app-header .account-info .profile-menu-wrapper .profile-submenu-hover-target .icon-wrapper .profile-submenu-wrapper .profile-submenu .profile-submenu-items-list .profile-submenu-item.logout {
  border-top: 1px solid #f0f0f0;
  height: 54px;
}
.app-header .account-info .profile-menu-wrapper .profile-submenu-hover-target .icon-wrapper .profile-submenu-wrapper .profile-submenu .profile-submenu-items-list .profile-submenu-item.logout > a {
  height: 54px;
}
.app-header .account-info .profile-menu-wrapper .profile-submenu-hover-target .icon-wrapper .profile-submenu-wrapper .profile-submenu .profile-submenu-items-list .profile-submenu-item.logout > a .profile-submenu-item-icon {
  height: 54px;
  line-height: 54px;
}
.app-header .account-info .profile-menu-wrapper .profile-submenu-hover-target .icon-wrapper .profile-submenu-wrapper .profile-submenu .profile-submenu-items-list .profile-submenu-item.logout > a .profile-submenu-item-link {
  line-height: 54px;
}
.app-header .account-info .profile-menu-wrapper .profile-submenu-hover-target .icon-wrapper:hover > a .fa {
  cursor: pointer;
  text-decoration: none;
  color: #ffaa35;
}
.app-header .account-info .profile-menu-wrapper .profile-submenu-hover-target .icon-wrapper:hover .profile-submenu-wrapper .profile-submenu {
  display: block;
}
.app-header .search {
  padding-top: 13px;
  box-sizing: border-box;
  padding-left: 10px;
  margin-right: 20px;
  min-width: 380px;
}
.page-content {
  top: 64px;
}
.block-default {
  padding: 12px 12px 12px 24px;
}
.block-default > div.header {
  height: 48px;
  line-height: 48px;
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 12px;
  font-size: 16px;
}
.block-accent {
  padding: 12px 12px 12px 24px;
  background: #f9f9f9;
}
.block-accent > div.header {
  height: 48px;
  border-bottom: 1px solid #dddddd;
  margin-bottom: 12px;
}
.block-accent > div.header .title {
  width: 50%;
  height: 48px;
  float: left;
  font-size: 16px;
  line-height: 48px;
}
.block-accent > div.header .actions {
  margin-left: 50%;
  text-align: right;
}
/* #region media */
@media screen and (max-width: 1200px) {
  .app-header .search {
    min-width: 240px;
  }
}
/* #endregion */
@import '../../global/variables.less';
.banner-image-loader {
  width: 100%;
  border-width: 2px;
  border-style: dashed;
  border-radius: 6px;
  padding: 24px;
  box-sizing: border-box;
  transition: background linear 200ms;
}
.banner-image-loader.no-previews {
  border-color: #ffe3c0;
  background-image: linear-gradient(-45deg, #fffcf8 25%, transparent 25%, transparent 50%, #fffcf8 50%, #fffcf8 75%, transparent 75%, white);
  background-size: 50px 50px;
  transition: background-image linear 100ms;
}
.banner-image-loader.with-previews {
  border-color: #ffe3c0;
}
.banner-image-loader.dragging {
  border-color: #fcc579;
  background-image: linear-gradient(-45deg, #fff5e9 25%, transparent 25%, transparent 50%, #fff5e9 50%, #fff5e9 75%, transparent 75%, white);
  background-size: 50px 50px;
  transition: background-image linear 100ms;
}
.banner-image-loader.with-previews.dragging {
  border-color: #ffe3c0;
  background-image: linear-gradient(-45deg, #fffcf8 25%, transparent 25%, transparent 50%, #fffcf8 50%, #fffcf8 75%, transparent 75%, white);
  background-size: 50px 50px;
  transition: background-image linear 100ms;
}
.banner-image-loader input[type=file] {
  visibility: hidden;
  width: 1px;
  margin: 0;
  padding: 0;
}
.banner-image-loader > .message > .text {
  text-align: center;
  margin-bottom: 24px;
}
.banner-image-loader > .message > .text:first-child {
  margin-top: 72px;
}
.banner-image-loader > .message > .btn-search-container {
  margin-bottom: 72px;
  text-align: center;
}
.banner-image-loader > .message .btn {
  background: white !important;
}
.banner-image-loader .previews-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 24px;
}
.banner-image-loader .previews-container .preview {
  height: 201px;
  border: 1px solid #f0f0f0;
  background: white;
}
.banner-image-loader .previews-container .preview > .image {
  height: 200px;
  width: 100%;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  align-items: center;
  box-sizing: border-box;
  padding: 8px;
}
.banner-image-loader .previews-container .preview > .image > img {
  max-width: 100%;
  max-height: 100%;
  margin-left: auto;
  margin-right: auto;
}
.banner-image-loader .previews-container .preview .info-wrapper {
  width: 100%;
  height: 1px;
  position: relative;
}
.banner-image-loader .previews-container .preview .info-wrapper .info-mask,
.banner-image-loader .previews-container .preview .info-wrapper .info-data {
  opacity: 0;
  width: 100%;
  height: 200px;
  position: absolute;
  top: -200px;
  left: 0;
  transition: opacity ease-out 140ms;
}
.banner-image-loader .previews-container .preview .info-wrapper .info-mask {
  background: white;
}
.banner-image-loader .previews-container .preview .info-wrapper .info-data {
  width: 100%;
  height: 100%;
}
.banner-image-loader .previews-container .preview .info-wrapper .info-data .info-data-content {
  height: 200px;
}
.banner-image-loader .previews-container .preview .info-wrapper .info-data .info-data-content > .img-name,
.banner-image-loader .previews-container .preview .info-wrapper .info-data .info-data-content > .img-dimensions,
.banner-image-loader .previews-container .preview .info-wrapper .info-data .info-data-content > .img-size {
  height: 32px;
  line-height: 32px;
  text-align: center;
  padding: 0 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  background: rgba(249, 249, 249, 0.9);
  color: #191f25;
}
.banner-image-loader .previews-container .preview .info-wrapper .info-data .info-data-content > .img-name {
  border-radius: 6px 6px 0 0;
}
.banner-image-loader .previews-container .preview .info-wrapper .info-data .info-data-content > .img-size {
  border-radius: 0 0 6px 6px;
}
.banner-image-loader .previews-container .preview .info-wrapper .info-data .info-data-content > .img-delete {
  height: 104px;
  text-align: center;
  padding-top: 36px;
  box-sizing: border-box;
}
.banner-image-loader .previews-container .preview .info-wrapper .info-data .info-data-content > .img-delete .fa {
  font-size: 32px;
  width: 42px;
  height: 42px;
  line-height: 42px;
  color: #808080;
  border-radius: 21px;
  border: 1px solid #f0f0f0;
  background: rgba(255, 255, 255, 0.5);
  transition: all linear 200ms;
}
.banner-image-loader .previews-container .preview .info-wrapper .info-data .info-data-content > .img-delete .fa:hover {
  cursor: pointer;
  color: #e47f44;
  border-color: #fcc579;
  background: rgba(255, 255, 255, 0.8);
}
.banner-image-loader .previews-container .preview:hover {
  border-color: #dddddd;
}
.banner-image-loader .previews-container .preview:hover > .image {
  -webkit-filter: blur(1px);
  -moz-filter: blur(1px);
  filter: blur(1px);
}
.banner-image-loader .previews-container .preview:hover .info-mask {
  opacity: 0.5;
}
.banner-image-loader .previews-container .preview:hover .info-data {
  opacity: 1;
}
.banner-image-loader .previews-container .load-another {
  border: 1px solid #f0f0f0;
  height: 200px;
  width: 200px;
  display: none;
}
.banner-image-loader > .actions {
  margin-top: 24px;
  display: none;
}
.banner-image-loader.with-previews > .message {
  text-align: center;
  margin-bottom: 48px;
}
.banner-image-loader.with-previews > .message > .text {
  display: inline-block;
  margin: 0;
  line-height: 24px;
}
.banner-image-loader.with-previews > .message > .btn-search-container {
  margin: 0 0 0 12px;
  text-align: center;
  display: inline-block;
}
.banner-image-loader.with-previews > .actions {
  display: block;
}
.form-input-label-default {
  display: block;
  height: 24px;
  line-height: 24px;
  color: #435261;
  font-size: 0.86rem;
  text-transform: uppercase;
  white-space: nowrap;
}
.optional-label {
  height: 12px;
  line-height: 12px;
  font-size: 10px;
  color: #9986d3;
}
.optional-label > span {
  font-size: 12px;
  text-transform: lowercase;
}
textarea {
  width: 100%;
  padding: 6px;
  box-sizing: border-box;
  color: #808080;
  border: 1px solid #dddddd;
}
textarea:focus,
textarea:active {
  border-color: #ffaa35;
  color: #435261;
}
.form .form-title {
  height: 20px;
  line-height: 20px;
  font-size: 16px;
  padding-left: 40px;
  margin-bottom: 40px;
}
.form .form-group {
  margin-bottom: 12px;
  text-align: left;
}
.form select {
  background-color: #fff;
}
.checkbox-component {
  margin-top: 10px;
}
textarea {
  width: 60%;
  margin-top: 15px;
}
input[type=text],
input[type=email],
input[type=tel],
input[type=password] {
  border: 1px solid #dddddd;
  font-family: 'Open Sans Condensed', Tahoma, Geneva, Verdana, sans-serif;
}
input[type=text]:focus,
input[type=email]:focus,
input[type=tel]:focus,
input[type=password]:focus {
  border: 1px solid #ffaa35;
}
input[type=text]::-webkit-input-placeholder,
input[type=email]::-webkit-input-placeholder,
input[type=tel]::-webkit-input-placeholder,
input[type=password]::-webkit-input-placeholder {
  color: #B8C7D6;
}
input[type=text]::-moz-placeholder,
input[type=email]::-moz-placeholder,
input[type=tel]::-moz-placeholder,
input[type=password]::-moz-placeholder {
  color: #B8C7D6;
  opacity: 1;
}
input[type=text]:-moz-placeholder,
input[type=email]:-moz-placeholder,
input[type=tel]:-moz-placeholder,
input[type=password]:-moz-placeholder {
  color: #B8C7D6;
  opacity: 1;
}
input[type=text]:-ms-input-placeholder,
input[type=email]:-ms-input-placeholder,
input[type=tel]:-ms-input-placeholder,
input[type=password]:-ms-input-placeholder {
  color: #B8C7D6;
}
input[type=text]:focus::-webkit-input-placeholder,
input[type=email]:focus::-webkit-input-placeholder,
input[type=tel]:focus::-webkit-input-placeholder,
input[type=password]:focus::-webkit-input-placeholder {
  color: #808080;
}
input[type=text]:focus::-moz-placeholder,
input[type=email]:focus::-moz-placeholder,
input[type=tel]:focus::-moz-placeholder,
input[type=password]:focus::-moz-placeholder {
  color: #808080;
  opacity: 1;
}
input[type=text]:focus:-moz-placeholder,
input[type=email]:focus:-moz-placeholder,
input[type=tel]:focus:-moz-placeholder,
input[type=password]:focus:-moz-placeholder {
  color: #808080;
  opacity: 1;
}
input[type=text]:focus:-ms-input-placeholder,
input[type=email]:focus:-ms-input-placeholder,
input[type=tel]:focus:-ms-input-placeholder,
input[type=password]:focus:-ms-input-placeholder {
  color: #808080;
}
.form-group-help {
  font-size: 12px;
  color: #B8C7D6;
}
.input-help-hint {
  color: #808080;
  background: #e4f1fa;
  border-radius: 3px;
  padding: 8px 8px;
}
.form-group.to-datepicker {
  flex-basis: 350px;
}
.form-group.to-datepicker date-picker {
  display: inline-block;
  width: 180px;
}
.form-group.to-datepicker date-picker .datepicker-actions__button {
  display: none;
}
.form-group.to-datepicker date-picker input {
  width: 100% !important;
}
.alert {
  margin-left: calc(20% + 25px);
}
.group-submit {
  margin-top: 40px;
}
.page-report-edit .table-constructor {
  position: relative;
  top: 12px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 12px;
}
.page-report-edit .table-constructor .columns-selector {
  position: absolute;
  right: calc(100% - 35%);
  width: 35%;
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}
.page-report-edit .table-constructor .columns-selector .ng-scrollbar-visible {
  padding: 0 2px !important;
}
.page-report-edit .table-constructor .columns-list {
  width: calc(100% - 35%);
  margin-left: 35%;
  padding-left: 36px;
  box-sizing: border-box;
  min-height: 420px;
}
.page-report-edit .table-constructor .columns-list .no-columns-selected {
  text-align: center;
  padding-top: 120px;
}
.page-report-edit .submenu {
  margin: 36px 0 12px 0;
  display: -webkit-flex;
  display: flex;
  flex-wrap: nowrap;
  height: 36px;
  padding-left: 10%;
  padding-right: 25%;
  box-sizing: content-box;
  background: #f9f9f9;
  border: 1px solid #f0f0f0;
}
.page-report-edit .submenu .submenu-item {
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  box-sizing: border-box;
  position: relative;
  border: 2px solid transparent;
  transition: background-color linear 100ms;
  transition: border-color linear 100ms;
  height: 100%;
}
.page-report-edit .submenu .submenu-item > .text {
  color: #808080;
  text-align: center;
  display: block;
  width: 100%;
  height: 100%;
  line-height: 33px;
}
.page-report-edit .submenu .submenu-item:hover {
  background: white;
  cursor: pointer;
}
.page-report-edit .submenu .submenu-item.selected {
  background: white;
  border-color: #8676b6;
  height: 44px;
  top: -4px;
}
.page-report-edit .submenu .submenu-item.selected > .text {
  position: relative;
  top: 4px;
}
.page-report-edit .submenu:not(.readonly) .type-selector-item:hover {
  opacity: 1;
  cursor: pointer;
}
.page-report-edit .submenu:not(.readonly) .type-selector-item:hover > .text {
  color: #435261;
}
.page-report-edit .no-schedule {
  margin-top: 24px;
  padding-top: 24px;
  text-align: center;
  box-sizing: border-box;
  height: 65px;
}
.page-report-edit .email-recipients {
  padding-top: 4px;
}
.page-report-edit .email-recipients .input-text {
  margin: 3px 0 0 0;
}
.page-report-edit .email-recipients .icon > * {
  height: 42px;
  line-height: 42px;
  font-size: 18px;
  width: 42px;
  text-align: center;
  margin-top: 16px;
}
.page-report-edit .email-recipients .icon > *:hover {
  cursor: pointer;
}
.page-report-edit .email-recipients .icon-add {
  color: #9b92b9;
}
.page-report-edit .email-recipients .icon-add:hover {
  color: #8676b6;
}
.page-report-edit .email-recipients .icon-delete {
  color: #e69393;
}
.page-report-edit .email-recipients .icon-delete:hover {
  color: #ed5757;
}
.page-report-edit checkbox.holiday .checkbox-component-outer-label {
  color: #ed5757;
}
.page-report-edit .tabs {
  border: 1px solid #dddddd;
  margin-top: 22px;
  margin-bottom: 8px;
  height: 40px;
}
.page-report-edit .tabs .tab {
  height: 38px;
  line-height: 38px;
}
.star .fa {
  color: #808080;
}
.star:hover {
  cursor: pointer;
}
.star:hover .fa {
  color: #191f25;
}
.star.favorited .fa {
  color: #ffaa35;
}
.star.favorited:hover .fa {
  color: #e47f44;
}
.page-report-view .report-settings {
  z-index: 100;
  background: #f9f9f9;
  border-bottom: 1px solid #dddddd;
}
.page-report-view .report-settings .report-main-settings-wrapper {
  padding: 0 24px 12px 24px;
}
.page-report-view .report-settings .report-main-settings-wrapper .additional-settings-toggler {
  padding-top: 4px;
}
.page-report-view .report-settings .report-main-settings-wrapper .additional-settings-toggler .fa {
  margin-left: 8px;
  margin-right: 2px;
}
.page-report-view .report-settings .btn-container {
  padding-top: 22px;
  padding-left: 24px;
}
.page-report-view .report-settings .report-additional-settings-wrapper {
  height: 1px;
  width: 100%;
  left: 0;
  right: 0;
  position: relative;
  z-index: 200;
}
.page-report-view .report-settings .report-additional-settings-wrapper .tabs {
  border: 1px solid #f0f0f0;
}
.page-report-view .report-settings .report-additional-settings-wrapper .tabs .tab {
  line-height: 34px !important;
}
.page-report-view .report-settings .report-additional-settings-wrapper .report-additional-settings {
  z-index: 300;
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  height: 380px;
  left: 0;
  top: -12px;
  background: #f9f9f9;
  border-bottom: 1px solid #dddddd;
  padding: 0 24px 12px 24px;
}
.page-report-view .report-settings .report-additional-settings-wrapper .report-additional-settings:after {
  content: "";
  display: block;
  height: 18px;
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  left: 0;
  top: 380px;
  background: rgba(128, 128, 128, 0.15);
  z-index: 400;
  background: -moz-linear-gradient(top, rgba(128, 128, 128, 0.15) 0%, rgba(255, 255, 255, 0) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(128, 128, 128, 0.15)), color-stop(100%, rgba(255, 255, 255, 0)));
  background: -webkit-linear-gradient(top, rgba(128, 128, 128, 0.15) 0%, rgba(255, 255, 255, 0) 100%);
  background: -o-linear-gradient(top, rgba(128, 128, 128, 0.15) 0%, rgba(255, 255, 255, 0) 100%);
  background: -ms-linear-gradient(top, rgba(128, 128, 128, 0.15) 0%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(to bottom, rgba(128, 128, 128, 0.15) 0%, rgba(255, 255, 255, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0);
}
.page-report-view .report-settings .report-additional-settings-wrapper .report-additional-settings .cells-container {
  min-height: 250px;
}
.page-report-view .report-settings .report-additional-settings-wrapper .report-additional-settings .cells-container .column-line {
  display: block;
  margin-bottom: 24px;
}
.page-report-view .report-settings .report-additional-settings-wrapper .report-additional-settings .cells-container .column-line .column-group {
  display: block;
}
.page-report-view .report-settings .report-additional-settings-wrapper .report-additional-settings .cells-container .column-line .column-group .column-item {
  display: inline-block;
  min-width: 140px;
}
.page-report-view .report-settings .report-additional-settings-wrapper .report-additional-settings .cells-container .column-line .column-group .column-item .checkbox-component {
  margin-top: 0;
  padding-top: 8px;
}
.page-report-view .chart-wrapper .chart {
  width: 100%;
  height: 300px;
  display: block;
}
.page-report-view .data-table thead {
  height: 32px;
  line-height: 32px;
}
.page-report-view .data-table thead th {
  height: 32px;
  line-height: 32px;
  white-space: nowrap;
  border-bottom: 3px solid #f0f0f0;
}
.page-report-view .data-table thead th.header-cell-metric {
  text-align: right;
}
.page-report-view .data-table thead th.header-cell-metric.hovered {
  cursor: pointer;
  border-bottom-color: #dddddd;
}
.page-report-view .data-table thead th.header-cell-ordering {
  width: 32px;
  padding: 0;
}
.page-report-view .data-table thead th.header-cell-ordering .column-ordering {
  height: 32px;
  width: 32px;
}
.page-report-view .data-table thead th.header-cell-ordering .column-ordering .column-ordering-desc,
.page-report-view .data-table thead th.header-cell-ordering .column-ordering .column-ordering-asc {
  height: 14px;
  width: 32px;
  text-align: center;
  line-height: 14px;
}
.page-report-view .data-table thead th.header-cell-ordering .column-ordering .column-ordering-desc .fa,
.page-report-view .data-table thead th.header-cell-ordering .column-ordering .column-ordering-asc .fa {
  height: 100%;
  line-height: 14px;
  color: #B8C7D6;
}
.page-report-view .data-table thead th.header-cell-ordering .column-ordering .column-ordering-desc:hover,
.page-report-view .data-table thead th.header-cell-ordering .column-ordering .column-ordering-asc:hover {
  cursor: pointer;
}
.page-report-view .data-table thead th.header-cell-ordering .column-ordering .column-ordering-desc:hover .fa,
.page-report-view .data-table thead th.header-cell-ordering .column-ordering .column-ordering-asc:hover .fa {
  color: #435261;
}
.page-report-view .data-table thead th.header-cell-ordering .column-ordering.asc .column-ordering-asc .fa,
.page-report-view .data-table thead th.header-cell-ordering .column-ordering.desc .column-ordering-desc .fa {
  color: #ffaa35;
}
.page-report-view .data-table thead th.header-cell-ordering.hovered {
  border-bottom-color: #dddddd;
}
.page-report-view .data-table thead th.selected {
  border-bottom-color: #c7bee5;
}
.page-report-view .data-table thead th.selected.hovered {
  border-bottom-color: #8676b6;
}
.page-report-view .data-table tbody td {
  white-space: nowrap;
}
.page-report-view .data-table tfoot td {
  white-space: nowrap;
}
.report-filter {
  margin-bottom: 24px;
  border: 1px solid white;
  z-index: 50;
}
.report-filter .search-outer-wrapper {
  margin-top: 3px;
  z-index: 60;
  box-sizing: border-box;
  position: relative;
  top: 0;
  left: 0;
  height: 42px;
}
.report-filter .search-outer-wrapper .ng-scrollbar-visible,
.report-filter .search-outer-wrapper ng-scrollbar {
  background-color: transparent !important;
}
.report-filter .search-outer-wrapper .search-wrapper {
  height: 42px;
  box-sizing: border-box;
  z-index: 100;
  position: relative;
  top: 0;
  left: 0;
}
.report-filter .search-outer-wrapper .search-wrapper .search-border {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 42px;
  z-index: 250;
  box-sizing: border-box;
  border: 1px solid #dddddd;
  background: white;
}
.report-filter .search-outer-wrapper .search-wrapper .search-border .search-reset-wrapper {
  width: 32px;
  height: 42px;
  float: right;
}
.report-filter .search-outer-wrapper .search-wrapper .search-border .search-reset-wrapper .search-reset-icon {
  width: 32px;
  height: 42px;
  text-align: center;
  line-height: 40px;
}
.report-filter .search-outer-wrapper .search-wrapper .search-border .search-reset-wrapper .search-reset-icon > .fa {
  color: #B8C7D6;
}
.report-filter .search-outer-wrapper .search-wrapper .search-border .search-reset-wrapper .search-reset-icon:hover {
  cursor: pointer;
}
.report-filter .search-outer-wrapper .search-wrapper .search-border .search-reset-wrapper .search-reset-icon:hover > .fa {
  color: #e47f44;
}
.report-filter .search-outer-wrapper .search-wrapper .search-border .search-input-wrapper {
  margin-right: 32px;
  height: 40px;
}
.report-filter .search-outer-wrapper .search-wrapper .search-border .search-input-wrapper input {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0 0 0 10px;
  line-height: 40px;
  color: #808080;
  outline: none;
  display: block;
  border-width: 0;
}
.report-filter .search-outer-wrapper .search-wrapper .search-border .search-input-wrapper input:focus {
  border-width: 0;
}
.report-filter .search-outer-wrapper .search-wrapper .search-list {
  position: absolute;
  box-sizing: border-box;
  top: 41px;
  left: 0;
  width: 100%;
  height: 158px;
  z-index: 200;
  border: 1px solid #dddddd;
  border-top-color: #f0f0f0;
  background: white;
}
.report-filter .search-outer-wrapper .search-wrapper .search-list .ng-scrollbar-visible {
  padding: 4px !important;
}
.report-filter .search-outer-wrapper .search-wrapper .search-list .search-list-item {
  height: 42px;
  line-height: 42px;
  box-sizing: border-box;
  padding: 2px 8px;
  color: #808080;
  white-space: nowrap;
}
.report-filter .search-outer-wrapper .search-wrapper .search-list .search-list-item > span {
  font-size: 14px;
  height: 36px;
  line-height: 36px;
  display: inline-block;
  box-sizing: border-box;
}
.report-filter .search-outer-wrapper .search-wrapper .search-list .search-list-item > .item-id {
  background: #f9f9f9;
  color: #808080;
  transition: all linear 100ms;
  padding: 0 4px;
  margin-right: 4px;
  border-radius: 3px;
  height: 22px;
  line-height: 21px;
  margin-top: 10px;
}
.report-filter .search-outer-wrapper .search-wrapper .search-list .search-list-item > .item-name {
  white-space: nowrap;
}
.report-filter .search-outer-wrapper .search-wrapper .search-list .search-list-item:hover {
  color: #191f25;
  background: #f9f9f9;
  cursor: pointer;
}
.report-filter .search-outer-wrapper .search-wrapper .search-list .search-list-item:hover > .item-id {
  color: #e47f44;
}
.report-filter .search-outer-wrapper .search-wrapper .search-list .search-list-item.selected:hover {
  cursor: default;
}
.report-filter .search-outer-wrapper .search-wrapper .search-list .search-list-item.selected:hover > span {
  color: #808080;
}
.report-filter .search-outer-wrapper .search-wrapper .search-list .search-list-item.selected:hover > .item-id {
  background: #f9f9f9;
  color: #808080;
}
.report-filter .search-outer-wrapper:hover .search-border {
  border-color: #fcc579;
}
.report-filter .search-outer-wrapper.opened {
  z-index: 70;
}
.report-filter .search-outer-wrapper.opened .search-wrapper {
  height: 199px;
  /*
                -webkit-box-shadow: 0px 0px 18px 1px darken(@color-block-background, 10%);
                -moz-box-shadow: 0px 0px 18px 1px darken(@color-block-background, 10%);
                box-shadow: 0px 0px 18px 1px darken(@color-block-background, 10%);
                */
}
.report-filter .search-outer-wrapper.opened .search-wrapper .search-border {
  border-color: #ffaa35;
  border-bottom-color: #ffe3c0;
}
.report-filter .search-outer-wrapper.opened .search-wrapper .search-list {
  border-color: #ffaa35;
  border-top-color: #ffe3c0;
}
.report-filter .selected-list {
  padding-left: 24px;
  padding-top: 4px;
  min-height: 45px;
  box-sizing: border-box;
}
.report-filter .selected-list .selected-list-item {
  display: inline-block;
  box-sizing: border-box;
  height: 42px;
  line-height: 41px;
  padding: 0 12px 0 8px;
  background: #f9f9f9;
  border-radius: 3px;
  margin-right: 6px;
  margin-bottom: 6px;
}
.report-filter .selected-list .selected-list-item > .fa {
  margin-left: 6px;
  color: #808080;
}
.report-filter .selected-list .selected-list-item:hover {
  cursor: pointer;
}
.report-filter .selected-list .selected-list-item:hover > .fa {
  color: #e47f44;
}
.report-filter .selected-list .selected-list-item > span {
  display: inline-block;
  box-sizing: border-box;
  vertical-align: top;
  height: 41px;
  line-height: 41px;
}
.report-filter .selected-list .selected-list-item > .item-id {
  border: 1px solid #dddddd;
  background: #dddddd;
  margin-top: 10px;
  border-radius: 3px;
  padding: 0 4px;
  margin-right: 6px;
  height: 21px;
  line-height: 19px;
  color: white;
}
.report-filter .selected-list .selected-list-item > .item-name {
  height: 42px;
  line-height: 41px;
  margin-right: 4px;
  max-width: 260px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.report-filter.report-filter-string .input-wrapper {
  height: 42px;
  padding-top: 3px;
}
.report-filter.report-filter-string .input-wrapper input {
  height: 42px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 10px;
}
.report-filter.report-filter-string .btn-add-string {
  margin-top: 3px;
  padding-left: 12px;
  padding-right: 12px;
  text-align: center;
}
.report-filter.report-filter-string .btn-add-string .fa {
  margin-right: 0;
}
.report-filter.report-filter-string .btn-add-string[disabled] .fa {
  color: #e9e9e9;
}
.report-filter.report-filter-string .btn-add-string[disabled]:hover .fa {
  color: #e9e9e9;
}
.report-filter.report-filter-string .selected-list .selected-list-item {
  padding-left: 12px;
}
.report-filters {
  min-height: 160px;
}
.report-filters .no-filters {
  padding-top: 48px;
  text-align: center;
}
.report-filters .btn-add-filter {
  margin-left: 0;
}
.report-filters .filter-selector .selected-count {
  font-size: 12px;
  color: #808080;
}
.page-report-edit__column-list__column-item {
  height: 46px;
  margin-bottom: 12px;
  background: white;
  border: 1px solid white;
  transition: background linear 100ms;
  transition: border-color linear 100ms;
  border-radius: 2px;
}
.page-report-edit__column-list__column-item .handler {
  opacity: 0;
  height: 46px;
  width: 20px;
  float: left;
  display: block;
  text-align: center;
  padding-top: 6px;
}
.page-report-edit__column-list__column-item .handler .handler-item {
  height: 6px;
  margin: 4px 7px 0 7px;
  width: 6px;
  border-radius: 3px;
  background: #e7e6ed;
}
.page-report-edit__column-list__column-item .name {
  height: 46px;
  line-height: 46px;
  float: left;
  width: calc(60% - 20px - 12px);
  box-sizing: border-box;
  padding-left: 12px;
  text-transform: lowercase;
}
.page-report-edit__column-list__column-item .sorting {
  height: 46px;
  float: left;
  width: calc(100% - calc(60% - 20px - 12px) - 20px - 40px);
  box-sizing: border-box;
  padding-left: 12px;
}
.page-report-edit__column-list__column-item .sorting .sorting-list {
  height: 46px;
  width: 24px;
  float: left;
  padding-top: 4px;
}
.page-report-edit__column-list__column-item .sorting .sorting-list .sorting-list-item {
  display: inline-block;
  box-sizing: border-box;
  height: 19px;
  line-height: 18px;
  color: #B8C7D6;
  border-radius: 2px;
  padding: 0 6px;
  transition: background-color linear 100ms;
}
.page-report-edit__column-list__column-item .sorting .sorting-list .sorting-list-item:hover {
  cursor: pointer;
  color: #e4c293;
}
.page-report-edit__column-list__column-item .sorting .sorting-list .sorting-list-item.selected {
  color: #e47f44;
}
.page-report-edit__column-list__column-item .sorting .sorting-value {
  float: left;
  margin-left: 12px;
  height: 46px;
  line-height: 46px;
}
.page-report-edit__column-list__column-item .remove {
  height: 46px;
  width: 40px;
  text-align: center;
  float: right;
  color: #eee3d6;
}
.page-report-edit__column-list__column-item .remove .fa {
  line-height: 46px;
}
.page-report-edit__column-list__column-item .remove:hover {
  color: #ffaa35;
  cursor: pointer;
}
.page-report-edit__column-list__column-item.cdk-drag-placeholder {
  opacity: 0;
}
.page-report-edit__column-list__column-item.cdk-drag-preview {
  background: #f9f9f9;
  border: 1px solid #f0f0f0;
}
.page-report-edit__column-list__column-item.cdk-drag-preview .handler,
.page-report-edit__column-list__column-item.cdk-drag-preview .name {
  cursor: move;
  opacity: 1;
}
.page-report-edit__column-list__column-item.cdk-drag-preview .handler-item {
  background-color: #9b92b9;
}
.page-report-edit__column-list__column-item.cdk-drag-disabled {
  border-color: white;
  background-image: linear-gradient(-45deg, #f9f9f9 25%, transparent 25%, transparent 50%, #f9f9f9 50%, #f9f9f9 75%, transparent 75%, white);
  background-size: 50px 50px;
  transition: background-image linear 100ms;
}
.page-report-edit__column-list__column-item.cdk-drag-disabled:hover {
  background: none !important;
  border-color: white !important;
  background-image: linear-gradient(-45deg, #f9f9f9 25%, transparent 25%, transparent 50%, #f9f9f9 50%, #f9f9f9 75%, transparent 75%, white) !important;
  background-size: 50px 50px !important;
  transition: background-image linear 100ms !important;
}
.page-report-edit__column-list__column-item.cdk-drag-disabled .handler {
  opacity: 0 !important;
}
.page-report-edit__column-list__column-item.cdk-drag-disabled .handler,
.page-report-edit__column-list__column-item.cdk-drag-disabled .name {
  cursor: auto !important;
}
.page-report-edit__column-list__column-item.cdk-drag-disabled .name,
.page-report-edit__column-list__column-item.cdk-drag-disabled .sorting {
  color: #808080;
}
.page-report-edit__column-list__column-item:last-child {
  margin-bottom: 0;
}
.report-columns-list {
  margin-bottom: 24px;
}
.report-columns-list .columns-list-header {
  height: 36px;
}
.report-columns-list .columns-list-header .header-text,
.report-columns-list .columns-list-header .list-actions,
.report-columns-list .columns-list-header .sorting-title,
.report-columns-list .columns-list-header .sort-type-selector {
  height: 36px;
  display: inline-block;
  width: 20%;
  float: left;
}
.report-columns-list .columns-list-header .header-text {
  line-height: 36px;
  font-weight: bolder;
  color: #808080;
  width: 20%;
}
.report-columns-list .columns-list-header .list-actions {
  line-height: 36px;
  width: 40%;
}
.report-columns-list .columns-list-header .sorting-title {
  line-height: 36px;
  width: 15%;
}
.report-columns-list .columns-list-header .sort-type-selector {
  transition: opacity ease-out 400ms;
  font-size: 12px;
  float: right;
  width: 25%;
}
.report-columns-list .columns-list-header .sort-type-selector > .header {
  color: #808080;
  margin-right: 12px;
  line-height: 12px;
  height: 12px;
}
.report-columns-list .columns-list-header .sort-type-selector > .items {
  height: 14px;
  white-space: nowrap;
}
.report-columns-list .columns-list-header .sort-type-selector > .items > .item {
  width: 50%;
  padding: 4px 6px;
  line-height: 14px;
  box-sizing: border-box;
  border: 1px solid #f0f0f0;
  transition: background-color linear 100ms;
  white-space: normal;
  display: block;
  float: left;
  text-align: center;
}
.report-columns-list .columns-list-header .sort-type-selector > .items > .item:hover {
  cursor: pointer;
}
.report-columns-list .columns-list-header .sort-type-selector > .items > .item.selected {
  background: #ffaa35;
  color: white;
  border-color: #ffaa35;
}
.report-columns-list .columns-list-header .sort-type-selector > .items > .item.selected:hover {
  cursor: default;
  background: #ffaa35;
  border-color: #ffaa35;
}
.report-columns-list .columns-list-header .sort-type-selector.invisible {
  opacity: 0;
}
.report-columns-list .page-report-edit__column-list {
  padding: 6px;
  box-sizing: border-box;
  border: 2px dotted #f0f0f0;
  transition: border-color ease-in 200ms;
  border-radius: 2px;
  margin-top: 12px;
}
.report-columns-list .page-report-edit__column-list.cdk-drop-list .page-report-edit__column-list__column-item .handler {
  opacity: 1;
}
.report-columns-list .page-report-edit__column-list.cdk-drop-list .page-report-edit__column-list__column-item:hover {
  background: #f9f9f9;
  border: 1px solid #f0f0f0;
}
.report-columns-list .page-report-edit__column-list.cdk-drop-list .page-report-edit__column-list__column-item:hover .handler,
.report-columns-list .page-report-edit__column-list.cdk-drop-list .page-report-edit__column-list__column-item:hover .name {
  cursor: move;
  opacity: 1;
}
.report-columns-list .page-report-edit__column-list.cdk-drop-list .page-report-edit__column-list__column-item:hover .handler-item {
  background-color: #9b92b9;
}
.report-columns-list .page-report-edit__column-list.cdk-drop-list-dragging {
  border-color: #ffe3c0;
}
.report-column-selector .column-group .column-group-header {
  height: 36px;
  line-height: 36px;
  font-weight: bolder;
  color: #808080;
  border-bottom: 2px solid #f0f0f0;
  margin-bottom: 4px;
  padding-left: 6px;
}
.report-column-selector .column-group .columns-group-list {
  margin-bottom: 12px;
}
.report-column-selector .column-group .columns-group-list .column-item {
  height: 36px;
  line-height: 36px;
  box-sizing: border-box;
  margin-right: 6px;
  padding-left: 6px;
  border-radius: 4px;
}
.report-column-selector .column-group .columns-group-list .column-item .name {
  display: inline-block;
  height: 36px;
  line-height: 36px;
}
.report-column-selector .column-group .columns-group-list .column-item .add,
.report-column-selector .column-group .columns-group-list .column-item .remove {
  display: inline-block;
  height: 36px;
  width: 36px;
  border-radius: 4px;
  line-height: 36px;
  float: right;
  color: #B8C7D6;
  text-align: center;
  transition: color linear 100ms;
}
.report-column-selector .column-group .columns-group-list .column-item .add .fa {
  margin-left: 2px;
}
.report-column-selector .column-group .columns-group-list .column-item .remove {
  display: none;
}
.report-column-selector .column-group .columns-group-list .column-item .remove .fa {
  color: #e4c293;
}
.report-column-selector .column-group .columns-group-list .column-item .remove:hover {
  cursor: pointer;
}
.report-column-selector .column-group .columns-group-list .column-item .remove:hover .fa {
  color: #ffaa35;
}
.report-column-selector .column-group .columns-group-list .column-item:hover {
  background: #f9f9f9;
  cursor: default;
}
.report-column-selector .column-group .columns-group-list .column-item:hover .add {
  background-color: #f0f0f0;
  color: #808080;
}
.report-column-selector .column-group .columns-group-list .column-item:hover .add:hover {
  background: #8676b6;
  color: white;
  cursor: pointer;
}
.report-column-selector .column-group .columns-group-list .column-item.disabled {
  color: #B8C7D6;
}
.report-column-selector .column-group .columns-group-list .column-item.disabled .add {
  display: none;
}
.report-column-selector .column-group .columns-group-list .column-item.disabled .remove {
  display: block;
}
.report-column-selector .column-group .columns-group-list .column-item.disabled:hover {
  cursor: auto;
  background: white;
}
.page-billing-payments .button-wrapper {
  padding: 19px 0;
}
.page-billing-payments .total-in,
.page-billing-payments .total-out {
  display: inline-block;
  border-right: 2px solid #f0f0f0;
  padding: 18px 36px;
  text-align: right;
}
.page-billing-payments .total-in .title,
.page-billing-payments .total-out .title {
  text-transform: uppercase;
  color: #808080;
}
.page-billing-payments .total-in .value,
.page-billing-payments .total-out .value {
  font-size: 24px;
  color: #8676b6;
}
.page-billing-payments .total-in .value {
  color: #35971e;
}
.page-billing-payments .total-out .value {
  color: #ed5757;
}
.page-billing-payments .data-table tbody td.neutral span {
  visibility: hidden;
}
.page-billing-payments .data-table tbody td.inflow {
  color: #35971e;
}
.page-billing-payments .data-table tbody td.outflow {
  color: #ed5757;
}
.page-dashboard .block-date-periods {
  height: 28px;
  text-align: right;
  margin-top: 12px;
}
.page-dashboard .block-date-periods .date-periods {
  height: 28px;
  display: inline-block;
}
.page-dashboard .block-date-periods .date-periods .tabs {
  height: 28px;
  padding-right: 12px;
}
.page-dashboard .block-date-periods .date-periods .tabs .tab {
  white-space: nowrap;
  box-sizing: border-box;
  transition: background-color linear 100ms;
  transition: color linear 100ms;
}
.page-dashboard .block-date-periods .date-periods .tabs .tab > span {
  display: inline-block;
  height: 28px;
  line-height: 26px;
  vertical-align: top;
  margin: 0 12px;
}
.page-dashboard .block-date-periods .date-periods .tabs .tab.selected {
  background: #8676b6;
  border-radius: 2px;
  color: white;
}
.page-dashboard .block-summary {
  background: #f9f9f9;
  padding: 0 12px;
  margin: 12px 0;
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  height: 140px;
  box-sizing: border-box;
}
.page-dashboard .block-summary .summary-item {
  float: left;
  padding: 12px;
  height: 140px;
  box-sizing: border-box;
}
.page-dashboard .block-summary .summary-item .summary-item-inner .metric-warning-wrapper {
  position: relative;
  z-index: 200;
  top: 3px;
  left: 0;
  width: 100%;
  height: 1px;
}
.page-dashboard .block-summary .summary-item .summary-item-inner .metric-warning-wrapper .metric-warning {
  position: absolute;
  right: 0;
  width: 32px;
  height: 32px;
  top: -4px;
  line-height: 32px;
  text-align: center;
}
.page-dashboard .block-summary .summary-item .summary-item-inner .metric-warning-wrapper .metric-warning > .fa {
  color: #ed5757;
}
.page-dashboard .block-summary .summary-item .summary-item-inner .metric-warning-wrapper .metric-warning:hover > .fa {
  cursor: pointer;
  color: #e82929;
}
.page-dashboard .block-summary .summary-item .summary-item-inner .metric-warning-wrapper .metric-warning-text {
  position: absolute;
  right: 0;
  width: 100%;
  min-height: 86px;
  box-sizing: border-box;
  top: 28px;
  background: #f8e8ea;
  border: 1px solid #ed5757;
  border-radius: 3px;
  padding: 6px;
}
.page-dashboard .block-summary .summary-item .summary-item-inner .metric-name {
  padding: 0 12px;
  height: 24px;
  line-height: 24px;
  font-size: 16px;
  text-align: center;
  margin-bottom: 24px;
  color: #808080;
}
.page-dashboard .block-summary .summary-item .summary-item-inner .growth-value {
  margin-top: 4px;
  height: 20px;
  text-align: center;
  margin-bottom: 20px;
}
.page-dashboard .block-summary .summary-item .summary-item-inner .growth-value > span {
  color: white;
  border-radius: 3px;
  display: inline-block;
  font-weight: bolder;
  line-height: 20px;
  font-size: 12px;
  padding: 0 6px;
}
.page-dashboard .block-summary .summary-item .summary-item-inner .metric-value {
  margin-top: 4px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  font-size: 22px;
  margin-bottom: 8px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
.page-dashboard .block-summary .summary-item .summary-item-inner .metric-selector {
  height: 32px;
  position: relative;
  z-index: 100;
  width: 100%;
  height: 1px;
  top: 0;
  left: 0;
}
.page-dashboard .block-summary .summary-item .summary-item-inner .metric-selector .axis-left,
.page-dashboard .block-summary .summary-item .summary-item-inner .metric-selector .axis-right {
  position: absolute;
  height: 32px;
  width: 32px;
  top: -36px;
  line-height: 32px;
  text-align: center;
  border-radius: 16px;
  opacity: 0.7;
  transition: color linear 100ms;
  transition: opacity linear 100ms;
}
.page-dashboard .block-summary .summary-item .summary-item-inner .metric-selector .axis-left:hover,
.page-dashboard .block-summary .summary-item .summary-item-inner .metric-selector .axis-right:hover {
  cursor: pointer;
  opacity: 1;
}
.page-dashboard .block-summary .summary-item .summary-item-inner .metric-selector .axis-left {
  left: 0;
}
.page-dashboard .block-summary .summary-item .summary-item-inner .metric-selector .axis-left .fa {
  transform: rotate(45deg);
  position: relative;
  left: -1px;
}
.page-dashboard .block-summary .summary-item .summary-item-inner .metric-selector .axis-right {
  right: 0;
}
.page-dashboard .block-summary .summary-item .summary-item-inner .metric-selector .axis-right .fa {
  transform: rotate(-45deg);
  position: relative;
  top: -1px;
}
.page-dashboard .block-summary .summary-item .summary-item-inner .metric-color-marker {
  height: 8px;
}
.page-dashboard .block-summary .summary-item.positive .growth-value > span {
  background: #87c478;
}
.page-dashboard .block-summary .summary-item.negative .growth-value > span {
  background: #ed5757;
}
.page-dashboard .block-summary .summary-item.unselected .axis-left,
.page-dashboard .block-summary .summary-item.unselected .axis-right {
  color: #808080;
}
.page-dashboard .block-summary .summary-item.unselected .axis-left:hover,
.page-dashboard .block-summary .summary-item.unselected .axis-right:hover {
  color: #191f25;
}
.page-dashboard .block-summary .summary-item.selected-left .axis-left,
.page-dashboard .block-summary .summary-item.selected-right .axis-left,
.page-dashboard .block-summary .summary-item.selected-left .axis-right,
.page-dashboard .block-summary .summary-item.selected-right .axis-right {
  color: white;
}
.page-dashboard .block-summary .summary-item.selected-right .axis-left {
  display: none;
}
.page-dashboard .block-summary .summary-item.selected-left .axis-right {
  display: none;
}
.page-dashboard .block-summary .summary-item-divider {
  height: 114px;
  width: 2px;
  float: left;
  margin-top: 12px;
  margin-bottom: 12px;
  background: #f0f0f0;
}
.page-dashboard .block-summary.block-summary-admin .summary-item {
  width: calc(16.66% - 2px);
}
.page-dashboard .block-chart-main {
  margin-bottom: 12px;
}
.page-dashboard .block-chart-main .chart-main {
  width: 100%;
  height: 300px;
  display: block;
}
.page-dashboard .block-charts-details .block-charts-details-item {
  width: 33.33%;
  float: left;
  height: 218px;
}
.page-dashboard .block-charts-details .block-charts-details-item > .item-header {
  height: 42px;
  line-height: 42px;
  text-align: center;
  background: #f9f9f9;
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
}
.page-dashboard .block-charts-details .block-charts-details-item .chart-details {
  width: 100%;
  box-sizing: border-box;
  padding: 12px 24px;
  height: 100%;
  display: block;
}
.page-dashboard .block-charts-details .block-charts-details-divider {
  height: 28px;
  width: 2px;
  float: left;
  margin-left: 12px;
  margin-top: 6px;
  margin-bottom: 6px;
  background: #f0f0f0;
}
.banner-pixel-constructor .input-container {
  box-sizing: border-box;
  margin-bottom: 6px;
}
.banner-pixel-constructor .input-container .input-text {
  margin: 0;
}
.banner-pixel-constructor .input-container .icon {
  display: inline-block;
  padding-top: 14px;
  width: 100%;
  text-align: center;
}
.banner-pixel-constructor .input-container .icon > * {
  height: 42px;
  line-height: 42px;
  font-size: 18px;
}
.banner-pixel-constructor .input-container .icon > *:hover {
  cursor: pointer;
}
.banner-pixel-constructor .input-container .icon-add {
  color: #9b92b9;
}
.banner-pixel-constructor .input-container .icon-add:hover {
  color: #8676b6;
}
.banner-pixel-constructor .input-container .icon-delete {
  color: #e69393;
}
.banner-pixel-constructor .input-container .icon-delete:hover {
  color: #ed5757;
}
.banner-pixel-constructor .input-container.opened .input-container-inner .form-input-wrapper > .macroses-wrapper .macroses-icon {
  border-radius: 0 0 3px 3px;
  height: 35px;
  top: -4px;
  background: white;
  padding-top: 7px;
  border-color: #dddddd;
  border-top-color: white;
  color: #808080;
  z-index: 2;
}
.banner-pixel-constructor .input-container.opened .input-container-inner .form-input-wrapper > .macroses-wrapper .macroses-icon .brace {
  color: #808080;
}
.banner-pixel-constructor .input-container.opened .input-container-inner .form-input-wrapper > .macroses-wrapper .macroses-icon:hover {
  color: #435261;
  background: linear-gradient(180deg, white, #f9f9f9);
}
.banner-pixel-constructor .input-container.opened .input-container-inner .form-input-wrapper > .macroses-wrapper .macroses-list {
  display: block;
  z-index: 1;
}
.banner-type-selector {
  height: 64px;
  padding-top: 17px;
}
.banner-type-selector .banner-type-selector-inner {
  background: #f9f9f9;
  box-sizing: border-box;
  border: 1px solid #dddddd;
  height: 42px;
}
.banner-type-selector .banner-type-selector-inner .go-home {
  width: 40px;
  text-align: right;
  margin-right: 24px;
  height: 40px;
  display: block;
  line-height: 38px;
  float: left;
  box-sizing: border-box;
  color: #808080;
}
.banner-type-selector .banner-type-selector-inner .go-home * {
  height: 40px;
  line-height: 40px;
  display: inline-block;
}
.banner-type-selector .banner-type-selector-inner .go-home .fa {
  line-height: 40px;
  font-size: 18px;
  height: 40px;
  color: #B8C7D6;
  transition: color linear 500ms;
}
.banner-type-selector .banner-type-selector-inner .go-home.clickable .fa {
  color: #8676b6;
}
.banner-type-selector .banner-type-selector-inner .go-home.clickable:hover {
  cursor: pointer;
  color: #5e547c;
}
.banner-type-selector .banner-type-selector-inner .item {
  float: left;
  padding: 0 48px;
  height: 40px;
  display: block;
  line-height: 35px;
  color: #808080;
  font-size: 16px;
  position: relative;
  top: 0;
  box-sizing: border-box;
  border: 2px solid transparent;
  transition: color linear 200ms;
  transition: background-color linear 200ms;
  transition: border-color linear 200ms;
}
.banner-type-selector .banner-type-selector-inner .item:hover {
  cursor: pointer;
  color: #191f25;
  background: white;
}
.banner-type-selector .banner-type-selector-inner .item.selected {
  background: white;
  height: 50px;
  line-height: 45px;
  top: -5px;
  border: 2px solid #8676b6;
  color: #191f25;
}
.banner-type-selector .banner-type-selector-inner .item.selected:hover {
  cursor: auto;
  color: #191f25;
}
.page-banner-edit {
  margin-top: 12px;
}
.page-banner-edit-html textarea,
.page-banner-edit-interactive textarea {
  width: 100% !important;
  margin-top: 0 !important;
}
.page-banner-edit-html div.code .outer,
.page-banner-edit-interactive div.code .outer {
  width: 100%;
  height: 180px;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  overflow-y: scroll;
}
.page-banner-edit-html div.code .inner,
.page-banner-edit-interactive div.code .inner {
  width: 100%;
  min-height: 100%;
  padding-left: 36px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-image: url(../../assets/images/code-numbers.png);
  background-repeat: no-repeat;
  font-family: Courier New, Courier, monospace;
  box-sizing: border-box;
  overflow: visible;
  white-space: pre;
}
.page-banner-edit-html div.code .inner:focus,
.page-banner-edit-interactive div.code .inner:focus {
  color: #191f25;
}
.page-banner-video-inline .video-file-item .icon-container {
  margin-top: 24px;
  height: 74px;
  box-sizing: border-box;
}
.page-banner-video-inline .video-file-item .icon-container > .fa {
  height: 44px;
  line-height: 44px;
  width: 100%;
  text-align: center;
}
.page-banner-native-mobile .preview-container {
  width: 100%;
  margin-top: 24px;
}
.page-campaigns-list .campaign-type-icon {
  border-bottom: 1px dotted #bbbbbb;
  color: #435261;
  padding: 0 4px;
  height: 14px;
  line-height: 12px;
  box-sizing: border-box;
  margin-top: 4px;
  display: inline-block;
}
.page-campaigns-list .campaign-type-icon:hover {
  cursor: help;
}
.page-campaign-edit .line-items-list {
  margin-top: 20px;
}
.page-campaign-edit .line-items-list .line-item {
  border: 1px solid #dddddd;
  border-bottom-width: 2px;
  background: #fefefe;
  height: 64px;
  margin-bottom: 20px;
}
.page-campaign-edit .line-items-list .line-item .name {
  float: left;
  width: 280px;
  padding: 10px 0 0 10px;
}
.page-campaign-edit .line-items-list .line-item .settings {
  margin-left: 210px;
}
.type-selector {
  display: -webkit-flex;
  display: flex;
  flex-wrap: nowrap;
  height: 130px;
  padding-left: 10%;
  padding-right: 25%;
  box-sizing: content-box;
  background: #f9f9f9;
  border: 1px solid #f0f0f0;
}
.type-selector .type-selector-item {
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  box-sizing: border-box;
  position: relative;
  border: 2px solid transparent;
  transition: background-color linear 100ms;
  transition: border-color linear 100ms;
}
.type-selector .type-selector-item > .text {
  color: #808080;
  text-align: center;
  padding: 0 12px;
}
.type-selector .type-selector-item > .text > .title {
  margin-top: 57px;
  height: 20px;
  line-height: 20px;
  transition: all linear 200ms;
  font-size: 1.428rem;
  white-space: nowrap;
}
.type-selector .type-selector-item > .text > .description {
  display: none;
  margin-top: 16px;
  height: 12px;
  line-height: 12px;
  color: #B8C7D6;
  font-size: 12px;
  margin-left: 24px;
  margin-right: 24px;
}
.type-selector .type-selector-item:hover {
  background: white;
}
.type-selector .type-selector-item.disabled,
.type-selector .type-selector-item.disabled:hover {
  cursor: default !important;
  background: #f9f9f9;
}
.type-selector .type-selector-item.disabled > .text,
.type-selector .type-selector-item.disabled:hover > .text {
  color: #B8C7D6 !important;
}
.type-selector .type-selector-item.selected {
  background: white;
  border-color: #8676b6;
  height: 142px;
  top: -6px;
}
.type-selector .type-selector-item.selected > .text {
  position: relative;
  top: 6px;
}
.type-selector .type-selector-item.selected > .text > .title {
  margin-top: 47px;
  color: #191f25;
  font-size: 1.143rem;
}
.type-selector .type-selector-item.selected > .text > .description {
  display: block;
}
.type-selector:not(.readonly) .type-selector-item:hover {
  opacity: 1;
  cursor: pointer;
}
.type-selector:not(.readonly) .type-selector-item:hover > .text {
  color: #435261;
}
.targeting-data-table-container .data-table-container-header {
  height: 32px;
  border-bottom: 1px solid #dddddd;
}
.targeting-data-table-container .data-table-container-header > div {
  float: left;
  height: 30px;
  line-height: 29px;
  font-weight: bolder;
}
.targeting-data-table-container .data-table {
  border-bottom: 0;
}
.targeting-data-table-container .data-table tr td {
  height: 36px;
  line-height: 35px;
  box-sizing: border-box;
  padding: 0;
}
.page-group-edit .targeting-selector-header {
  height: 42px;
  line-height: 42px;
  padding-left: 64px;
  background: #f9f9f9;
  border: 1px solid #f0f0f0;
  border-width: 1px 0 1px 0;
  font-weight: normal;
  font-size: 120%;
  margin-top: 18px;
}
.page-group-edit .targeting-selector .targeting-menu {
  float: left;
  width: 22%;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 6px;
  box-sizing: border-box;
  height: 388px;
  border-right: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  vertical-align: auto;
}
.page-group-edit .targeting-selector .targeting-menu .page-submenu-item {
  height: 32px;
  line-height: 32px;
  padding: 0 12px 0 24px;
  transition: background-color linear 100ms;
}
.page-group-edit .targeting-selector .targeting-menu .page-submenu-item .selected-marker {
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background: #ffaa35;
  display: inline-block;
  opacity: 0;
  position: relative;
  left: -8px;
  top: 1px;
}
.page-group-edit .targeting-selector .targeting-menu .page-submenu-item:hover {
  cursor: pointer;
  background: #e7e6ed;
}
.page-group-edit .targeting-selector .targeting-menu .page-submenu-item.opened {
  background: #8676b6;
  color: white;
  border-right: 1px solid #8676b6;
}
.page-group-edit .targeting-selector .targeting-menu .page-submenu-item.selected .selected-marker {
  opacity: 1;
}
.page-group-edit .targeting-selector .targeting-container {
  margin-left: 22%;
  width: 78%;
  box-sizing: border-box;
}
.page-group-edit .targeting-selector .targeting-container .targeting-body-wrapper {
  box-sizing: border-box;
  padding-top: 12px;
}
.page-group-edit .targeting-selector .targeting-container .targeting-body-wrapper .targeting-body {
  height: 328px;
}
.page-group-edit .targeting-selector .targeting-container .targeting-body-wrapper .targeting-footer {
  height: 46px;
  background: #f9f9f9;
  border: 1px solid #f0f0f0;
  border-width: 1px 0;
}
.page-group-edit .targeting-selector .targeting-container .targeting-body-wrapper .targeting-footer tabs {
  width: auto !important;
}
.page-group-edit .targeting-selector .targeting-container .targeting-body-wrapper .targeting-footer tabs > div.tabs {
  margin-left: auto;
  margin-right: auto;
  width: auto !important;
  height: 24px;
  margin-top: 10px;
}
.page-group-edit .targeting-selector .targeting-container .targeting-body-wrapper .targeting-footer tabs > div.tabs .tab {
  max-width: 25%;
  height: 24px;
  line-height: 24px;
  background: #f9f9f9;
  border: 1px solid #f0f0f0;
  color: #808080;
}
.page-group-edit .targeting-selector .targeting-container .targeting-body-wrapper .targeting-footer tabs > div.tabs .tab.selected {
  background: #ffaa35;
  color: white;
}
.page-group-edit .targetings-list {
  min-height: 440px;
}
.page-audiences-edit .audience-constructor-header {
  height: 42px;
  line-height: 42px;
  padding-left: 64px;
  background: #f9f9f9;
  border: 1px solid #f0f0f0;
  border-width: 1px 0 1px 0;
  font-weight: normal;
  font-size: 120%;
  margin-top: 18px;
  font-size: 1em !important;
}
.page-audiences-edit .audience-constructor {
  position: relative;
  z-index: 1;
  margin-top: 12px;
}
.page-audiences-edit .audience-constructor .sources-list {
  float: left;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 6px;
  box-sizing: border-box;
  height: 490px;
  border-right: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  vertical-align: auto;
  padding-top: 0;
  width: 100%;
}
.page-audiences-edit .audience-constructor .sources-list .page-submenu-item {
  height: 32px;
  line-height: 32px;
  padding: 0 12px 0 24px;
  transition: background-color linear 100ms;
}
.page-audiences-edit .audience-constructor .sources-list .page-submenu-item .selected-marker {
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background: #ffaa35;
  display: inline-block;
  opacity: 0;
  position: relative;
  left: -8px;
  top: 1px;
}
.page-audiences-edit .audience-constructor .sources-list .page-submenu-item:hover {
  cursor: pointer;
  background: #e7e6ed;
}
.page-audiences-edit .audience-constructor .sources-list .page-submenu-item.opened {
  background: #8676b6;
  color: white;
  border-right: 1px solid #8676b6;
}
.page-audiences-edit .audience-constructor .sources-list .page-submenu-item.selected .selected-marker {
  opacity: 1;
}
.page-audiences-edit .audience-constructor .sources-list .ng-scrollbar-visible {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.page-audiences-edit .audience-constructor .search-wrapper {
  height: 52px;
}
.page-audiences-edit .audience-constructor .search-wrapper > .search-container {
  width: calc(100% - 12px * 3 - 6px);
  height: 34px;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  margin-left: 12px;
  margin-right: 36px;
  box-sizing: border-box;
}
.page-audiences-edit .audience-constructor .search-wrapper > .search-container > input.search,
.page-audiences-edit .audience-constructor .search-wrapper > .search-container > input.search:focus {
  width: calc(100% - 20px);
  height: 32px;
  line-height: 32px;
  margin: 0;
  padding: 0 6px;
  box-sizing: border-box;
  border: none;
}
.page-audiences-edit .audience-constructor .search-wrapper > .search-container > .fa {
  width: 20px;
  height: 32px;
  line-height: 26px;
  color: #c7bee5;
  vertical-align: middle;
}
.page-audiences-edit .audience-constructor .search-wrapper > .search-container > .fa:hover {
  cursor: pointer;
  color: #e47f44;
}
.page-audiences-edit .audience-constructor .search-wrapper.focused > .search-container {
  border-color: #ffaa35;
}
.page-audiences-edit .audience-constructor .all-segments-list {
  height: 438px;
}
.page-audiences-edit .audience-constructor .all-segments-list .segment {
  height: 56px;
  line-height: 56px;
  margin-left: 24px;
  background: #f9f9f9;
  margin: 12px 0 12px 12px;
  border: 1px solid transparent;
  box-sizing: border-box;
  margin-right: 8px;
}
.page-audiences-edit .audience-constructor .all-segments-list .segment > .id {
  padding-left: 12px;
}
.page-audiences-edit .audience-constructor .all-segments-list .segment .operation {
  color: #ffaa35;
  font-weight: bolder;
  text-transform: uppercase;
}
.page-audiences-edit .audience-constructor .all-segments-list .segment .segment-name {
  height: 28px;
  line-height: 28px;
}
.page-audiences-edit .audience-constructor .all-segments-list .segment .segment-description {
  height: 28px;
  line-height: 14px;
  font-size: 12px;
  color: #B8C7D6;
}
.page-audiences-edit .audience-constructor .all-segments-list .segment > .count {
  text-align: right;
  padding-right: 12px;
}
.page-audiences-edit .audience-constructor .all-segments-list .segment:first-child {
  margin-top: 0;
}
.page-audiences-edit .audience-constructor .all-segments-list .segment:hover {
  cursor: pointer;
  border: 1px solid #c7bee5;
}
.page-audiences-edit .audience-constructor .all-segments-list .segment.selected {
  background-color: #edeaf6;
}
.page-audiences-edit .audience-constructor .all-segments-list .segment.selected:hover {
  border-color: #8676b6;
}
.page-audiences-edit .audience-constructor .selected-segments-wrapper .auditory-operation-title {
  height: 88px;
}
.page-audiences-edit .audience-constructor .selected-segments-wrapper .auditory-operation-title > .title-wrapper {
  margin-bottom: 12px;
}
.page-audiences-edit .audience-constructor .selected-segments-wrapper .auditory-operation-title > .title-wrapper > .title {
  height: 34px;
  line-height: 34px;
  display: inline-block;
}
.page-audiences-edit .audience-constructor .selected-segments-wrapper .auditory-operation-title .tabs-wrapper {
  height: 24px;
}
.page-audiences-edit .audience-constructor .selected-segments-wrapper .auditory-operation-title .tabs-wrapper .tabs {
  height: 24px;
}
.page-audiences-edit .audience-constructor .selected-segments-wrapper .auditory-operation-title .tabs-wrapper .tabs .tab {
  height: 24px;
  line-height: 24px;
  border: 1px solid #f0f0f0;
}
.page-audiences-edit .audience-constructor .selected-segments-wrapper .selected-segments-container-wrapper .selected-segments-container {
  height: 402px;
}
.page-audiences-edit .audience-constructor .selected-segments-wrapper .selected-segments-container-wrapper .selected-segments-container .selected-segment {
  height: 30px;
  margin: 4px 8px;
  padding: 4px 0;
  border-bottom: 1px solid #f0f0f0;
}
.page-audiences-edit .audience-constructor .selected-segments-wrapper .selected-segments-container-wrapper .selected-segments-container .selected-segment > .name {
  display: inline-block;
  height: 30px;
  line-height: 30px;
  padding: 0 14px;
  width: calc(100% - 20px);
  float: left;
  box-sizing: border-box;
  text-overflow: ellipsis;
  overflow: hidden;
}
.page-audiences-edit .audience-constructor .selected-segments-wrapper .selected-segments-container-wrapper .selected-segments-container .selected-segment > .close {
  height: 30px;
  line-height: 30px;
  display: block;
  float: right;
  width: 20px;
  color: #B8C7D6;
}
.page-audiences-edit .audience-constructor .selected-segments-wrapper .selected-segments-container-wrapper .selected-segments-container .selected-segment > .close:hover {
  cursor: pointer;
  color: #e47f44;
}
.page-audiences-edit .audience-constructor .selected-segments-wrapper .selected-segments-container-wrapper .selected-segments-container .selected-segment:last-child {
  border: none;
}
.users-actions-info .btn-add-container {
  padding-top: 16px;
}
.users-actions-info .btn-add-container .btn {
  margin: 12px 0 0 0;
}
.users-actions-info .global-info {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: right;
}
.users-actions-info .global-info .global-info-item {
  display: inline-block;
  border-right: 2px solid #f0f0f0;
  padding: 12px 36px;
  width: 80px;
}
.users-actions-info .global-info .global-info-item .global-info-key {
  text-transform: uppercase;
  color: #808080;
}
.users-actions-info .global-info .global-info-item .global-info-value {
  font-size: 24px;
  color: #8676b6;
}
.users-actions-info .global-info .global-info-item .global-info-value:hover {
  text-decoration: underline;
  color: #5e547c;
  cursor: pointer;
}
.users-actions-info .global-info .global-info-item:last-child {
  border: none;
}
