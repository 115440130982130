@import '../../../../styles/global/variables.less';
@import '_variables.less';

.page-group-edit {
    .targeting-selector-header {
        .page-block-header();
    }

    .targeting-selector {

        .targeting-menu {
            .page-submenu(22%, @targeting-body-height + @targeting-footer-height + @block-interval-step);
        }

        .targeting-container {
            margin-left: 22%;
            width: 78%;
            box-sizing: border-box;

            .targeting-body-wrapper {
                box-sizing: border-box;
                padding-top: @block-interval-step;

                .targeting-body {
                    height: @targeting-body-height;
                }

                .targeting-footer {
                    height: @targeting-footer-height - 2px;
                    background: @color-block-background;
                    border: 1px solid @border-color-light;
                    border-width: 1px 0;

                    tabs {
                        width: auto !important;

                        > div.tabs {
                            margin-left: auto;
                            margin-right: auto;
                            width: auto !important;
                            height: 24px;
                            margin-top: 10px;

                            .tab {
                                max-width: 25%;
                                height: 24px;
                                line-height: 24px;
                                background: @color-block-background;
                                border: 1px solid @border-color-light;
                                color: @text-color-lighten;

                                &.selected {
                                    background: @color-accent;
                                    color: @text-color-inverse-active;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .targetings-list {
        min-height: 440px;
    }
}
