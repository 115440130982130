@import '../../../global/variables.less';

.banner-pixel-constructor {
    .input-container {
        box-sizing: border-box;
        margin-bottom: @block-interval-step / 2;

        .input-text {
            margin: 0;
        }

        .icon {
            display: inline-block;
            padding-top: 14px;
            width: 100%;
            text-align: center;

            > * {
                height: @text-input-height-default;
                line-height: @text-input-height-default;
                font-size: 18px;
            }
        }

        .icon > *:hover {
            cursor: pointer;
        }

        .icon-add {
            color: @color-primary-pale;
        }

        .icon-add:hover {
            color: @color-primary;
        }

        .icon-delete {
            color: @color-warning-default-pale;
        }

        .icon-delete:hover {
            color: @color-warning-default;
        }


        &.opened .input-container-inner .form-input-wrapper > .macroses-wrapper {
            .macroses-icon {
                border-radius: 0 0 3px 3px;
                height: 35px;
                top: -4px;
                background: @color-body-background;
                padding-top: 7px;
                border-color: @border-color-main;
                border-top-color: @color-body-background;
                color: @text-color-lighten;
                z-index: 2;

                .brace {
                    color: @text-color-lighten;
                }

                &:hover {
                    color: @text-color-main;
                    background: linear-gradient(180deg, @color-body-background, @color-block-background);
                }
            }

            .macroses-list {
                display: block;
                z-index: 1;
            }
        }
    }
}
