@import 'variables.less';
@import 'base.less';

.grid {
    >.cell {
        float: left;
        box-sizing: border-box;
        height: 100%;
        width: 100%;
    }

    .cell.cell-5 {
        width: 5%;
    }

    .cell.cell-10 {
        width: 10%;
    }

    .cell.cell-15 {
        width: 15%;
    }

    .cell.cell-20 {
        width: 20%;
    }

    .cell.cell-25 {
        width: 25%;
    }

    .cell.cell-30 {
        width: 30%;
    }

    .cell.cell-33 {
        width: 33.33%;
    }

    .cell.cell-35 {
        width: 35%;
    }

    .cell.cell-40 {
        width: 40%;
    }

    .cell.cell-45 {
        width: 45%;
    }

    .cell.cell-50 {
        width: 50%;
    }

    .cell.cell-55 {
        width: 55%;
    }

    .cell.cell-60 {
        width: 60%;
    }

    .cell.cell-65 {
        width: 65%;
    }

    .cell.cell-67 {
        width: 66.66%;
    }

    .cell.cell-70 {
        width: 70%;
    }

    .cell.cell-75 {
        width: 75%;
    }

    .cell.cell-80 {
        width: 80%;
    }

    .cell.cell-85 {
        width: 85%;
    }

    .cell.cell-90 {
        width: 90%;
    }

    .cell.cell-95 {
        width: 95%;
    }

    .cell.cell-100 {
        width: 100%;
    }

    .cell.offset-5 {
        margin-left: 5%;
    }

    .cell.offset-10 {
        margin-left: 10%;
    }

    .cell.offset-15 {
        margin-left: 15%;
    }

    .cell.offset-20 {
        margin-left: 20%;
    }

    .cell.offset-25 {
        margin-left: 25%;
    }

    .cell.offset-30 {
        margin-left: 30%;
    }

    .cell.offset-33 {
        margin-left: 33.33%;
    }

    .cell.offset-40 {
        margin-left: 40%;
    }

    .cell.offset-45 {
        margin-left: 45%;
    }

    .cell.offset-50 {
        margin-left: 50%;
    }

    .cell.offset-55 {
        margin-left: 55%;
    }

    .cell.offset-60 {
        margin-left: 60%;
    }

    .cell.offset-65 {
        margin-left: 65%;
    }

    .cell.offset-70 {
        margin-left: 70%;
    }

    .cell.offset-75 {
        margin-left: 75%;
    }

    .cell.offset-80 {
        margin-left: 80%;
    }

    .cell.offset-85 {
        margin-left: 85%;
    }

    .cell.offset-90 {
        margin-left: 90%;
    }

    .cell.offset-95 {
        margin-left: 95%;
    }
}

.grid::after {
    content: " ";
    display: table;
    clear: both;
}


.grid.padded-1 {
    > .cell {
        padding: 6px;
    }
}

.grid.padded-2 {
    > .cell {
        padding: 8px 12px;
    }
}

.grid.bordered {
    > .cell {
        outline: 1px solid @border-color-main;
    }
}
