@import '../variables.less';


@datepicker-height: @text-input-height-default;

.date-range-picker {
    display: inline-block;
    height: @datepicker-height;
    transition: background-color linear 100ms;

    > .model-label {
        display: inline-block;
        height: @datepicker-height;
        line-height: @datepicker-height - 2px;
        box-sizing: border-box;
        vertical-align: middle;
        padding-right: @block-interval-step;
        padding-left: @block-interval-step;
        border: 1px solid @border-color-light;
        border-radius: 3px 0 0 3px;
        background: @color-body-background;
        color: @text-color-lighten;
    }

    > .btn-toggle {
        height: @datepicker-height;
        box-sizing: border-box !important;
        vertical-align: middle;
        border: none;
        margin: 0;
        text-align: center;
        background: @color-primary-light;

        > .fa {
            margin: 0;
        }
    }


    .toggle-area {
        height: @datepicker-height;
        padding: 0 14px 0 14px;

        .model-label {
            transition: color linear 100ms;
            height: @datepicker-height;
            line-height: @datepicker-height;
            margin-right: 6px;
            color: @text-color-light;
        }

        i.fa {
            color: @color-accent-secondary-default;
        }
    }

    .date-range-picker-wrapper {
        position: relative;
        width: 1px;
        height: 1px;
        right: -8px;
        top: -9px;
        float: right;

        .date-range-picker-inner {
            -webkit-box-shadow: 0px 0px 46px 1px @color-shadow;
            -moz-box-shadow: 0px 0px 46px 1px @color-shadow;
            box-shadow: 0px 0px 46px 1px @color-shadow;
            cursor: default;
            display: block;
            position: absolute;
            width: 530px;
            right: 0;
            top: @datepicker-height + 16px;
            background: white;
            border: 1px solid @border-color-main;
            border-radius: 3px;
            padding: 10px;
            z-index: 1000;

            .btn-range-interval {
                margin: 3px;
            }

            .date-intervals{
                text-align: left;
            }
        }
    }

    .date-range-picker-wrapper:before {
        display: block;
        position: absolute;
        right: 13px;
        top: @datepicker-height + 6px;
        border-style: solid;
        border-width: 0 10px 10px 10px;
        border-color: transparent transparent @border-color-main transparent;
        box-sizing: content-box;
        width: 0;
        height: 0;
        content: "";
        z-index: 900;
    }

    .date-range-picker-wrapper:after {
        display: block;
        position: absolute;
        right: 13px;
        top: @datepicker-height + 7px;
        border-style: solid;
        border-width: 0 10px 10px 10px;
        border-color: transparent transparent white transparent;
        box-sizing: content-box;
        width: 0;
        height: 0;
        content: "";
        z-index: 1010;
    }
}


.date-range-picker:hover {
    cursor: pointer;

    > .model-label {
        border-color: @border-color-main;
        color: @text-color-main;
    }

    > .btn-toggle {
        background: @color-primary;

        > .fa {
            
        }
    }
}


.mydp {
    border: none !important;
    z-index: 3000 !important;

    .selector {
        border-radius: 0 !important;
        border: 1px solid @border-color-main !important;
        margin-bottom: 5px;


        .header {
            background: white !important;

            * {
                background: white !important;
            }

            .headertodaybtn {
                .mydpicon {
                    display: none !important;
                }

                > span {
                    background: transparent !important;
                }
            }

            .headertodaybtn:hover {
                background: @color-accent-secondary-default !important;
            }
        }

        .caltable {
            .daycell {
                padding: 0;
                height: 26px !important;
                transition: background-color linear 50ms;
                outline: none;

                .datevalue {
                    height: 26px;
                    line-height: 26px;

                    .markcurrday {
                    }
                }

                .markdate {
                    width: 24px;
                    height: 20px;
                    margin-top: 4px;
                    margin-left: 6px;
                    opacity: 0.11;
                    border-width: 8px;
                    background: @color-accent !important;
                    border-radius: 4px !important;
                }
            }

            .daycell.disabled, .daycell.disabled:hover {
                background: darken(@color-block-background, 5%);
            }

            .daycell:hover {
                background: @color-accent-secondary-default;
            }

            .weekdaytitle {
                background: @color-accent-lighten !important;
                color: white !important;
                height: 18px;
                line-height: 18px;
            }

            .currmonth {
                background: transparent;
                color: @text-color-main;
            }

            .selectedday, .selectedday:hover {
                background-color: @color-accent !important;
                border-radius: 3px;

                .datevalue {
                    color: white !important;
                }

                .markdate {
                    opacity: 0 !important;
                }
            }

            .selectedday:hover {
                color: white;
            }

            .highlight {
                color: @color-warning-default !important;
            }

            .markcurrday {
                text-decoration: none;
            }
        }
    }
}