@import '../../variables.less';
@import '_variables.less';

@arrow-width: 14px;
@arrow-height: 14px;

.dt-sortable-header {
    white-space: nowrap;
    height: @head-row-height;
    display: inline-block;

    .label {
        display: inline-block;
        margin-right: @block-interval-step;
        height: 100%;
        line-height: @head-row-height;
    }

    .sorting-arrows-wrapper {
        display: inline-block;
        box-sizing: border-box;
        height: @arrow-height * 2;
        top: (@head-row-height - @arrow-height*2) / 2 - 4px;
        position: relative;
        width: 14px;
        margin-left: 2px;

        .sorting-arrow {
            color: @border-color-main;
            cursor: pointer;
            display: block;
            line-height: 0;
            width: @arrow-width;
            height: @arrow-height;
            box-sizing: border-box;

            &-selected {
                color: @color-accent;
            }

            &:hover {
                color: @color-accent-darken;
            }
        }
    }
}
