@import 'variables.less';

button {
    border: 1px solid @border-color-main;
    background: white;
    padding: 5px 8px;
    border-radius: 3px;
    color: @text-color-main;
    outline: none !important;

    &:hover {
        cursor: pointer;
        color: @border-control-active-hover;
    }
}


/* #region BUTTONS */

.btn {
    color: white;
    text-align: center;
    border: 1px solid transparent;
    cursor: pointer;
    border-radius: 2px;
    display: inline-block;
    vertical-align: middle;
    box-sizing: content-box !important;
    transition: background ease-in-out 200ms;
    margin: 0 4px;

    .fa {
        margin-right: @block-interval-step;
    }

    &.no-left-margin {
        margin-left: 0;
    }

    &:hover {
        color: white;
        text-decoration: none;
    }
}




.btn-primary {
    background-color: @color-primary;

    &:hover {
        background-color: @color-primary-dark;
    }
}


.btn-accent {
    background-color: @color-accent-darken;

    &:hover {
        background-color: @color-accent;
    }
}


.btn-primary-inverse {
    border: 1px solid @color-primary-lighten;
    color: @color-primary;
    background: none;

    > * {
        color: @color-primary;
    }
}

.btn-primary-inverse:hover {
    color: @color-primary-dark;
    border: 1px solid @color-primary;

    > * {
        color: @color-primary-dark;
    }
}


.btn-accent-inverse {
    border: 1px solid @color-accent-lighten;
    color: @color-accent;
    background: none;

    > * {
        color: @color-accent;
    }
}

.btn-accent-inverse:hover {
    color: @color-accent-darken;
    border: 1px solid @color-accent;

    > * {
        color: @color-accent-darken;
    }
}


.btn-inverse {
    border: 1px solid @border-color-main;
    color: @text-color-lighten;
    background: none;

    .fa {
        color: @text-color-light;
    }
}

.btn-inverse:hover {
    border-color: @border-color-dark;
    color: @text-color-main;

    .fa {
        color: @text-color-lighten;
    }
}


.btn-big {
    padding: 12px 36px;
    font-size: @text-size-h3;
}

.btn-medium {
    padding: 11px 18px;
    font-size: @text-size-h4;
}

.btn-small {
    padding: 4px 8px;
    font-size: @text-size-main;
    height: 16px;
    line-height: 16px;
}

.btn-block {
    width: 100%;
    box-sizing: border-box !important;
    margin: 0 !important;
}

.btn.btn-light-bg {
    background: @color-body-background;
}

.btn[disabled], .btn[disabled]:hover {
    background: @border-color-dark;
    border: 1px solid @border-color-dark;
    color: white;
    cursor: default;
    transition: none !important;
}



.btn.fixed-width-24 {
    width: 16px;
}

.btn.fixed-width-80 {
    width: 80px;
}

.btn.fixed-width-100 {
    width: 100px;
}

.btn.fixed-width-120 {
    width: 120px;
}



.actions-menu {
    display: inline-block;
    height: 32px;
    width: 32px;
    vertical-align: top;

    .icon-container {
        transition: color linear 200ms;
        height: 100%;
        width: 32px;
        line-height: 32px;
        font-size: 18px;
        text-align: center;
        color: @color-success-default
    }

    .menu-container {
        width: 1px;
        height: 100%;

        .menu-relative-wrapper {
            position: relative;
            width: 1px;
            height: 100%;

            .menu {
                display: none;
                border: 1px solid @border-color-main;
                background: white;
                position: absolute;
                top: -32px;
                left: 32px;
                width: auto;
                height: auto;
                z-index: 1000;

                .nenu-items-list {
                    list-style-type: none;
                    margin: 0;
                    padding: 0;

                    .menu-item {
                        margin: 0;
                        padding: 0;
                        text-align: left;

                        a {
                            display: block;
                            width: 100%;
                            height: 32px;
                            line-height: 32px;
                            padding: 0 14px;
                            white-space: nowrap;
                            box-sizing: border-box;
                            transition: background-color ease-in 100ms;
                        }

                        a:hover {
                            background: @background-control-active-hover;
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }
}

.actions-menu:hover {
    .icon-container {
        cursor: pointer;
        color: @color-success-lighten;
    }

    .menu-container .menu-relative-wrapper .menu {
        display: block;
    }
}
