@import '../../variables.less';

@checkbox-height: 20px;


checkbox {
    outline: none;


    .checkbox-component {
        display: inline-block;
        vertical-align: middle;
        padding-top: @block-interval-step * 1.5;

        .checkbox-container {
            display: inline-block;
            cursor: pointer;
            width: @checkbox-height;
            height: @checkbox-height;
            line-height: @checkbox-height;
            border: 1px solid @border-color-main;
            background: @color-block-background;
            border-radius: 2px;
            text-align: center;
            font-weight: bolder;
            font-size: 10px;
            transition: background-color ease-in-out 100ms;

            i {
                font-size: 12px;
                color: @color-block-background;
                opacity: 0;
                transition: opacity ease-in-out 200ms;
            }
        }
    }


    .checkbox-component.active {
        .checkbox-container {
            background-color: @color-body-background;
            border-color: @border-control-active;

            i {
                color: @color-accent-darken;
                opacity: 1;
            }
        }
    }

    .checkbox-component.active:hover {
        .checkbox-container {
            border-color: @color-accent-darken;
            background-color: @color-accent-light;

            i {
                color: @color-accent-darken;
            }
        }
    }

    .checkbox-component.inactive:hover {
        .checkbox-container {
            border-color: @border-color-dark;
            //background-color: @color-accent-light;
            i {
                opacity: 1;
                color: @border-color-dark;
            }
        }
    }


    .checkbox-component.with-inner-label {
        height: @checkbox-height * 1.4;


        .checkbox-container {
            height: @checkbox-height * 1.4;
            line-height: @checkbox-height * 1.4 - 1px;
            font-size: 14px;
            width: auto;
            padding-left: 6px;
            padding-right: 6px;
            font-weight: normal;
        }

        .checkbox-component-inner-label {
            margin-left: 4px;
        }
    }

    .checkbox-component.with-inner-label.inactive {
        i {
            visibility: visible !important;
            color: @text-color-lighten;
        }
    }

    .checkbox-component.with-outer-label {
        width: auto;

        .checkbox-container {
            float: left;
        }

        .checkbox-component-outer-label {
            float: left;
            margin: 0 15px 0 6px;
            cursor: pointer;
            height: @checkbox-height + 2px;
            line-height: @checkbox-height + 2px;
        }
    }
}
