@import 'variables.less';

html, body {
    color: @text-color-main;
    background: @color-body-background;


    &.font-size-11 {
        font-size: 11px;
    }

    &.font-size-12 {
        font-size: 12px;
    }

    &.font-size-13 {
        font-size: 13px;
    }

    &.font-size-14 {
        font-size: 14px;
    }

    &.font-size-15 {
        font-size: 15px;
    }

    &.font-size-16 {
        font-size: 16px;
    }

    &.font-family-default {
        font-family: @font-family-default;
    }

    &.font-family-test {
        font-family: @font-family-test;
    }
}



.link-color-transition()
{
    transition: color linear 100ms;
}

a {
    color: @color-link-default;
    text-decoration: none;
    .link-color-transition();

    &:hover {
        color: @color-link-hover;
        text-decoration: underline;
    }

    &:active {
        color: @color-link-active;
        text-decoration: underline;
    }
}


*:focus{
    outline: none !important;
}


.as-link {
    color: @color-link-default;
    cursor: pointer;
    .link-color-transition();

    &:hover {
        color: @color-link-hover;
        text-decoration: underline;
    }

    &:active {
        color: @color-link-active;
    }
}


a.inverse, .as-link.inverse {
    color: @text-color-inverse;

    &:hover {
        color: @text-color-inverse-hover;
    }


    &:active {
        color: @text-color-inverse-active;
    }
}


.align-right{
    text-align: right !important;
}

.align-center{
    text-align: center !important;
}

.lowercase{
    text-transform: lowercase;
}

.uppercase {
    text-transform: uppercase;
}

.masked {
    position: relative;
}

.app-loading{
    width: 100%;
    margin-top: 20%;
}

.masked:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    content: "";
    z-index: 1000;
}

.masked:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 12%;
    min-width: 140px;
    height: 60px;
    line-height: 20px;
    top: ~"calc(50% - 52px)";
    margin: 0 44% 0 44%;
    border: 1px solid @border-color-main;
    background: white;
    padding: 5px;
    content: "";
    background-image: url(/dist/assets/img/loader.gif);
    background-repeat: no-repeat;
    background-position: center center;
    text-align: center;
    color: @text-color-lighten;
    font-size: 16px;
    padding: 15px 10px;
    z-index: 1100;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
}


[hidden] {
    display: none !important;
}

.nowrap{
    white-space: nowrap;
}

.unbreakable {
    break-inside: avoid;
    page-break-inside: avoid;
    -webkit-column-break-inside: avoid;
}

.clearfix:before,
.clearfix:after {
    content: " ";
    display: table;
}

.clearfix:after {
    clear: both;
}

.clearfix {
    *zoom: 1;
}

.border-top-1{
    border-top: 1px solid @border-color-main;
}

.border-bottom-1 {
    border-bottom: 1px solid @border-color-main;
}

.border-top-2 {
    border-top: 2px solid @border-color-main;
}

.border-bottom-2 {
    border-bottom: 2px solid @border-color-main;
}


.padding-top-05 {
    padding-top: @block-interval-step / 2;
}

.padding-top-1 {
    padding-top: @block-interval-step;
}

.padding-top-2 {
    padding-top: @block-interval-step * 2;
}

.padding-top-3 {
    padding-top: @block-interval-step * 3;
}

.padding-bottom-05 {
    padding-bottom: @block-interval-step / 2;
}

.padding-bottom-1 {
    padding-bottom: @block-interval-step;
}

.padding-bottom-2 {
    padding-bottom: @block-interval-step * 2;
}


.padding-left-05 {
    padding-left: @block-interval-step / 2;
}

.padding-left-1 {
    padding-left: @block-interval-step;
}

.padding-left-2 {
    padding-left: @block-interval-step * 2;
}

.padding-right-05 {
    padding-right: @block-interval-step / 2;
}

.padding-right-1 {
    padding-right: @block-interval-step;
}

.padding-right-2 {
    padding-right: @block-interval-step * 2;
}

.padding-05 {
    padding: @block-interval-step / 2;
}

.padding-1 {
    padding: @block-interval-step;
}

.padding-2 {
    padding: @block-interval-step * 2;
}


.margin-top-05 {
    margin-top: @block-interval-step / 2;
}

.margin-top-1 {
    margin-top: @block-interval-step;
}

.margin-top-2 {
    margin-top: @block-interval-step * 2;
}

.margin-bottom-05 {
    margin-bottom: @block-interval-step / 2;
}

.margin-bottom-1 {
    margin-bottom: @block-interval-step;
}

.margin-bottom-2 {
    margin-bottom: @block-interval-step * 2;
}

.text-primary {
    color: @color-primary;
}

.text-accent{
    color: @color-accent;
}

.text-inactive {
    color: @text-color-lighten;
}

.text-disabled {
    color: @text-color-light;
}

.text-small{
    font-size: 12px;
}

.text-large{
    font-size: 18px;
}

.icon-item-add, .icon-item-delete {
    font-size: 18px !important;
    height: @text-input-height-default;
    line-height: @text-input-height-default;
    width: @text-input-height-default;
    text-align: center;

    &:hover {
        cursor: pointer;
    }
}

.icon-item-add {
    color: @color-primary-pale;

    &:hover {
        color: @color-primary;
    }
}

.icon-item-delete {
    color: @color-warning-default-pale;

    &:hover {
        color: @color-warning-default;
    }
}



@media screen and (max-width: 992px) {
}

@media screen and (max-width: 768px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}
