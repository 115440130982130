@import '../../../global/variables.less';

.page-campaign-edit {

    .line-items-list {
        margin-top: 20px;

        .line-item {
            border: 1px solid @border-color-main;
            border-bottom-width: 2px;
            background: #fefefe;
            height: 64px;
            margin-bottom: 20px;

            .name {
                float: left;
                width: 280px;
                padding: 10px 0 0 10px;
            }

            .settings {
                margin-left: 210px;
            }
        }
    }
}
