@import '../../variables.less';

@height: @text-input-height-default;
@width: @text-input-height-default;

@margin: 8px;

.inline-block(@width, @height) {
    display: inline-block;
    box-sizing: border-box;
    vertical-align: middle;
    height: @height;
    width: @width;
}

.help-hint-wrapper {
    .inline-block(@width, @height);
    margin-top: @block-interval-step * 3;


    .text-container {
        display: block;
        position: relative;
        top: -1px;
        left: 0;
        width: @width;
        height: 1px;

        .text {
            //display: none;
            position: absolute;
            top: -@height - @block-interval-step - 2px;
            left: @width;
            min-width: 160px;
            min-height: @height + @block-interval-step * 2;
            max-height: 2* @height + @block-interval-step * 2;
            box-sizing: border-box;
            border: 1px solid @border-color-light;
            border-radius: 6px;
            background: @color-body-background;
            padding: @block-interval-step / 2 @block-interval-step;
        }
    }
}



.tool-tip {
    display: inline-block;
    position: relative;
    margin-left: 0.5em;

    .tool-tip__icon {
        .inline-block(@width - 2*@margin, @height - 2*@margin);
        text-align: center;
        line-height: @height - 2px - 2*@margin;
        color: @color-accent;
        font-weight: bolder;
        background: @color-accent-light;
        border: 1px solid @color-accent-light;
        border-radius: 4px;
        margin-top: @margin;
    }

    .tool-tip__icon:hover {
        cursor: pointer;
        color: @color-accent-darken;
        border-color: @color-accent-darken;
    }

    .tool-tip__info {
        border: 1px solid @border-color-light;
        border-radius: 6px;
        background: @color-body-background;
        padding: @block-interval-step / 2 @block-interval-step;
        display: none;
        position: absolute;
        left: 30px;
        top: -20px;
        width: 250px;
        z-index: 2;
    }


    .triangle() {
        content: "";
        position: absolute;
        left: -10px;
        top: 14px;
        border-style: solid;
        border-width: 10px 10px 10px 0;
        border-color: transparent @border-color-main;
    }

    .tool-tip__info:before {
        .triangle();
    }

    .tool-tip__info:after {
        .triangle();
        left: -9px;
        border-right-color: @color-body-background;
    }
}

.tool-tip:hover .tool-tip__info, 
.tool-tip:focus .tool-tip__info {
    display: inline-block;
}