@import '../../../global/variables.less';
@import '../../../global/forms.less';

@columns-list-header-height: 36px;

@column-item-height: 46px;
@item-handler-width: 20px;
@item-name-width: ~"calc(60% - @{item-handler-width} - @{block-interval-step})";
@item-sorting-width: ~ "calc(100% - @{item-name-width} - @{item-handler-width} - @{item-remove-width})";
@item-remove-width: 40px;

.item-hovered() {
    background: @color-block-background;
    border: 1px solid @border-color-light;

    .handler, .name {
        cursor: move;
        opacity: 1;
    }

    .handler-item {
        background-color: @color-primary-pale;
    }
}


// it is outside list rule because
// when dragging - removing from list to <body>
.page-report-edit__column-list__column-item {
    height: @column-item-height;
    margin-bottom: @block-interval-step;
    background: @color-body-background;
    border: 1px solid @color-body-background;
    transition: background linear 100ms;
    transition: border-color linear 100ms;
    border-radius: 2px;

    .handler {
        opacity: 0;
        height: @column-item-height;
        width: @item-handler-width;
        float: left;
        display: block;
        text-align: center;
        padding-top: 6px;

        .handler-item {
            height: 6px;
            margin: 4px 7px 0 7px;
            width: 6px;
            border-radius: 3px;
            background: @color-primary-light-pale;
        }
    }

    .name {
        height: @column-item-height;
        line-height: @column-item-height;
        float: left;
        width: @item-name-width;
        box-sizing: border-box;
        padding-left: @block-interval-step;
        text-transform: lowercase;
    }

    .sorting {
        height: @column-item-height;
        float: left;
        width: @item-sorting-width;
        box-sizing: border-box;
        padding-left: @block-interval-step;

        .sorting-list {
            height: @column-item-height;
            width: 24px;
            float: left;
            padding-top: 4px;

            .sorting-list-item {
                display: inline-block;
                box-sizing: border-box;
                height: @column-item-height / 2- 4px;
                line-height: @column-item-height / 2 - 5px;
                color: @text-color-light;
                border-radius: 2px;
                padding: 0 @block-interval-step/2;
                transition: background-color linear 100ms;

                &:hover {
                    cursor: pointer;
                    color: @color-accent-pale;
                }

                &.selected {
                    color: @color-accent-darken;
                }
            }
        }

        .sorting-value {
            //width: 130px;
            float: left;
            margin-left: @block-interval-step;
            height: @column-item-height;
            line-height: @column-item-height;
        }
    }

    .remove {
        height: @column-item-height;
        width: @item-remove-width;
        text-align: center;
        float: right;
        color: @color-accent-light-pale;

        .fa {
            line-height: @column-item-height;
        }

        &:hover {
            color: @color-accent;
            cursor: pointer;
        }
    }


    &.cdk-drag-placeholder {
        opacity: 0;
    }


    &.cdk-drag-preview {
        .item-hovered()
    }


    &.cdk-drag-disabled {
        .diagonal-lines-background(@color-body-background, @color-body-background, @color-block-background);

        &:hover {
            border-color: @color-body-background !important;
            background: none !important;
            .diagonal-lines-background(@color-body-background, @color-body-background, @color-block-background) !important;
        }

        .handler {
            opacity: 0 !important;
        }

        .handler, .name {
            cursor: auto !important;
        }

        .name, .sorting {
            color: @text-color-lighten;
        }
    }


    &:last-child {
        margin-bottom: 0;
    }
}



.report-columns-list {
    margin-bottom: @block-interval-step*2;

    .columns-list-header {
        height: @columns-list-header-height;

        .header-text, .list-actions, .sorting-title, .sort-type-selector {
            height: @columns-list-header-height;
            display: inline-block;
            width: 20%;
            float: left;
        }

        .header-text {
            line-height: @columns-list-header-height;
            font-weight: bolder;
            color: @text-color-lighten;
            width: 20%;
        }

        .list-actions {
            line-height: @columns-list-header-height;
            width: 40%;
        }

        .sorting-title {
            line-height: @columns-list-header-height;
            width: 15%;
        }

        .sort-type-selector {
            transition: opacity ease-out 400ms;
            font-size: 12px;
            float: right;
            width: 25%;

            > .header {
                color: @text-color-lighten;
                margin-right: @block-interval-step;
                line-height: 12px;
                height: 12px;
            }

            > .items {
                height: 14px;
                white-space: nowrap;

                > .item {
                    width: 50%;
                    padding: 4px 6px;
                    line-height: 14px;
                    box-sizing: border-box;
                    border: 1px solid @border-color-light;
                    transition: background-color linear 100ms;
                    white-space: normal;
                    display: block;
                    float: left;
                    text-align: center;

                    &:hover {
                        cursor: pointer;
                    }

                    &.selected {
                        background: @color-accent;
                        color: @text-color-inverse-active;
                        border-color: @color-accent;

                        &:hover {
                            cursor: default;
                            background: @color-accent;
                            border-color: @color-accent;
                        }
                    }
                }
            }

            &.invisible {
                opacity: 0;
            }
        }
    }

    .page-report-edit__column-list {
        padding: @block-interval-step/2;
        box-sizing: border-box;
        border: 2px dotted @border-color-light;
        transition: border-color ease-in 200ms;
        border-radius: 2px;
        margin-top: @block-interval-step;

        &.cdk-drop-list {
            .page-report-edit__column-list__column-item {
                .handler {
                    opacity: 1;
                }

                &:hover {
                    .item-hovered();
                }
            }
        }

        &.cdk-drop-list-dragging {
            border-color: @color-accent-light;
        }
    }
}
