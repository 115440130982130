@import '../../variables.less';

.field-validation-container {
    .icon {
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        width: 24px;
        height: @text-input-height-default;
        line-height: @text-input-height-default;

        .fa {
            color: @color-warning-default;
        }
    }

    .messages-wrapper {
        position: relative;
        top: -1px;
        left: 0;
        width: 1px;
        height: 1px;
        cursor: pointer;

        .messages {
            display: block;
            position: absolute;
            box-sizing: border-box;
            background: @color-warning-default;
            border: 1px solid @color-warning-default;
            color: @color-body-background;
            min-width: 200px;
            max-width: 320px;
            min-height: @text-input-height-default;
            height: auto;
            top: -@text-input-height-default;
            left: 16px;
            display: none;
            padding: 0 @block-interval-step;
            cursor: default;

            &::before {
                content: '';
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 10px 12px 10px 0;
                border-color: transparent @color-warning-default transparent transparent;
                position: absolute;
                top: 8px;
                left: -12px;
                display: block;
            }
        }

        .message {
            margin: @block-interval-step/2 0;
        }
    }

    &:hover {
        .icon {
            visibility: hidden;
        }

        .messages-wrapper {
            .messages {
                display: block;
            }
        }
    }
}
