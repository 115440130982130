@font-face {
  font-family: 'Roboto Condensed';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local('Roboto Condensed Light Italic'), local('RobotoCondensed-LightItalic'), url(https://fonts.gstatic.com/s/robotocondensed/v18/ieVg2ZhZI2eCN5jzbjEETS9weq8-19eDpCEYag.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Condensed';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Roboto Condensed Italic'), local('RobotoCondensed-Italic'), url(https://fonts.gstatic.com/s/robotocondensed/v18/ieVj2ZhZI2eCN5jzbjEETS9weq8-19e7CA.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Condensed';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local('Roboto Condensed Bold Italic'), local('RobotoCondensed-BoldItalic'), url(https://fonts.gstatic.com/s/robotocondensed/v18/ieVg2ZhZI2eCN5jzbjEETS9weq8-19eDtCYYag.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Roboto Condensed Light'), local('RobotoCondensed-Light'), url(https://fonts.gstatic.com/s/robotocondensed/v18/ieVi2ZhZI2eCN5jzbjEETS9weq8-33mZKCM.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Roboto Condensed'), local('RobotoCondensed-Regular'), url(https://fonts.gstatic.com/s/robotocondensed/v18/ieVl2ZhZI2eCN5jzbjEETS9weq8-59U.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Roboto Condensed Bold'), local('RobotoCondensed-Bold'), url(https://fonts.gstatic.com/s/robotocondensed/v18/ieVi2ZhZI2eCN5jzbjEETS9weq8-32meKCM.ttf) format('truetype');
}
/* #region media */
/* #endregion */
/* #region texts */
/* #endregion */
/* #region colors */
/*
    use to custom elements only
    not to standart browser inputs
*/
/* #endregion */
/* #region layout */
/* #endregion */
/* #region mixins */
/* #endregion*/
html,
body {
  min-height: 100%;
}
:host,
auth,
.auth-layout {
  height: 100%;
  display: block;
}
body.auth {
  background: #f9f9f9;
}
.auth-layout-header {
  height: 64px;
  border-bottom: 1px solid #dddddd;
  box-sizing: border-box;
  display: block;
}
.auth-layout-header .logo {
  width: 196px;
  float: left;
  height: 100%;
  background: #444444;
  box-sizing: border-box;
  padding-top: 12.54901961px;
}
.auth-layout-header .logo .str-1,
.auth-layout-header .logo .str-2 {
  display: block;
  width: 100%;
  text-align: center;
  height: 22.85714286px !important;
  line-height: 22.85714286px !important;
  font-weight: bolder;
  color: #e9e9e9;
}
.auth-layout-header .logo .str-1 {
  font-size: 18px;
}
.auth-layout-header .logo .str-2 {
  font-size: 11px;
  text-transform: uppercase;
}
.auth-layout-header .header-icon {
  float: left;
  width: 64px;
  height: 64px;
  line-height: 60px;
  font-size: 140%;
}
.auth-layout-header .header-icon .fa {
  width: 64px;
  height: 64px;
  line-height: 64px;
  color: #444444;
  font-size: 18px;
  text-align: center;
}
.auth-layout-header .breadcrumb-wrapper {
  float: left;
  width: 320px;
  height: 100%;
}
.auth-layout-header .breadcrumb-wrapper .breadcrumb .breadcrumb-item .link {
  display: inline-block;
  height: 14px;
  line-height: 14px;
  font-size: 12px;
  margin-top: 8px;
  margin-bottom: 6px;
}
.auth-layout-header .breadcrumb-wrapper .breadcrumb .breadcrumb-item .link a {
  color: #808080;
}
.auth-layout-header .breadcrumb-wrapper .breadcrumb .breadcrumb-item .link .arrow {
  margin: 0 8px;
  font-size: 10px;
  color: #B8C7D6;
}
.auth-layout-header .breadcrumb-wrapper .breadcrumb .breadcrumb-item .current-page-header {
  display: block;
  clear: left;
  font-size: 140%;
}
.auth-layout-header .locale {
  float: right;
  height: 100%;
  line-height: 64px;
  padding-right: 24px;
}
.auth-layout-content-wrapper {
  display: block;
  box-sizing: border-box;
  padding-top: 80px;
  padding-bottom: 50px;
  z-index: 200;
}
.auth-layout-content-wrapper .auth-layout-content {
  background: #f9f9f9;
  border: 1px solid #9b92b9;
  border-radius: 4px;
  margin-left: auto;
  margin-right: auto;
  width: 320px;
  padding: 24px 0 24px 0;
  -webkit-box-shadow: 0px 0px 34px 1px rgba(155, 146, 185, 0.5);
  -moz-box-shadow: 0px 0px 34px 1px rgba(155, 146, 185, 0.5);
  box-shadow: 0px 0px 34px 1px rgba(155, 146, 185, 0.5);
  z-index: 300;
}
.auth-layout-content-wrapper .auth-layout-content .input-text {
  z-index: 10;
  margin: 0;
}
.auth-layout-content-wrapper .auth-layout-content .input-text > .form-input-wrapper {
  z-index: 15;
}
.auth-layout-content-wrapper .auth-layout-content .input-text > .form-input-wrapper > .label-valued.visible {
  z-index: 30;
}
.auth-layout-content-wrapper .auth-layout-content .input-text > .form-input-wrapper > input {
  z-index: 20;
}
.auth-layout-content-wrapper .auth-layout-content .dropdown-wrapper {
  margin: 0;
}
.auth-layout-content-wrapper .auth-layout-content .auth-form-wrapper .links-container {
  line-height: 36px;
}
.auth-layout-content-wrapper .auth-links {
  margin-top: 60px;
}
.auth-layout-footer {
  height: 50px;
  line-height: 50px;
  box-sizing: border-box;
  z-index: 100;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  background: #f9f9f9;
  border-top: 1px solid #f0f0f0;
}
.auth-layout-footer .copyright {
  float: left;
  width: 50%;
  height: 49px;
  line-height: 49px;
  box-sizing: border-box;
  text-align: left;
  padding: 0 24px;
}
.auth-layout-footer .links {
  text-align: right;
  margin-left: 50%;
  padding-right: 24px;
}
.auth-layout-footer .links > span {
  display: inline-block;
  margin: 0 12px;
}
.logged-in {
  padding: 24px;
  margin-top: 48px;
  text-align: center;
}
