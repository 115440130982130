@import '../../../global/variables.less';

.star {
    .fa {
        color: @text-color-lighten;
    }

    &:hover {
        cursor: pointer;

        .fa {
            color: @text-color-darken;
        }
    }

    &.favorited {
        .fa {
            color: @color-accent;
        }

        &:hover {
            .fa {
                color: @color-accent-darken;
            }
        }
    }
}