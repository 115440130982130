@import '../variables.less';

@tab-height: @block-interval-step * 3;

.tabs {
    display: -webkit-flex;
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    height: @tab-height;// + 6px;
    //border: 1px solid @color-accent-lighten-pale;
    //padding: 0 3px;
    //border-width: 1px 0 1px 0;
    box-sizing: border-box;

    .tab {
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        height: 100%;
        line-height: @tab-height;
        text-align: center;
        background-color: @color-body-background;
    }

    .tab:hover {
        cursor: pointer;
        color: @text-color-darken;
    }


    .tab.selected {
        background-color: @color-accent;
        color: @text-color-inverse-active;
    }
}

.tabs.disabled{

}