@import '../../variables.less';

@search-block-height: 32px;
@buttons-block-width: 80px;

dt-search {
    .dt-search-wrapper {
        width: 100%;
        height: @search-block-height;
        border: 1px solid @border-color-light;
        border-radius: 3px;

        .input-block {
            width: ~"calc(100% - @{buttons-block-width} - 1px)";
            height: 100%;
            box-sizing: border-box;
            display: block;
            float: left;

            input {
                width: 100%;
                padding-left: 9px;
                line-height: @search-block-height - 2px;
                box-sizing: border-box;
                border: none;
                color: @text-color-lighten;
            }

            input:focus {
                color: @text-color-main;
            }
        }

        .buttons-block {
            width: @buttons-block-width;
            float: right;
            text-align: right;
            padding-right: 10px;
            box-sizing: border-box;

            .search-icon {
                display: inline-block;
                margin-top: 3px;
                width: @search-block-height - 6px;
                height: @search-block-height - 6px;
                line-height: @search-block-height - 6px;
                color: @color-primary-lighten;
                border-radius: 3px;
                text-align: center;
                vertical-align: middle;
                transition: color linear 100ms;
                transition: background-color linear 100ms;
                margin-left: 4px;
            }

            .search-icon:hover {
                color: white;
                background: @color-primary-lighten;
                cursor: pointer;
            }
        }
    }

    .dt-search-wrapper.opened {
        border-color: @border-color-main;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        .search-icon.search-icon-filters {
            background: @color-primary-lighten;
            color: white;
        }
    }


    .dt-search-wrapper.focused {
        border-color: @color-primary;

        .search-icon {
            color: @color-primary;
        }
    }


    .dt-search-wrapper.changed {
        .search-icon.search-icon-filters {
            background: @color-primary-lighten;
            color: white;
        }
    }


    .dt-filters-wrapper {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 1px;

        .dt-filters {
            position: absolute;
            top: -1px;
            text-align: left;
            left: 0;
            width: 100%;
            min-height: 110px;
            display: none;
            border: 1px solid @border-color-main;
            border-radius: 0 0 3px 3px;
            border-top-color: @border-color-light;
            background: white;
            z-index: 1000;
        }
    }

    .dt-filters-wrapper.opened {
        .dt-filters {
            display: block;
        }
    }

    .dt-filters-wrapper.opened.focused {
        .dt-filters {
            border-color: @color-primary-lighten;
        }
    }

    .dt-filters-actions {
        padding: 10px 20px;

        .apply-btn {
            margin-left: 50%;
            text-align: right;
        }

        .reset-link {
            float: left;
            width: 50%;
        }
    }
}
