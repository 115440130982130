@import '../../variables.less';
@import '_base.less';

@dropdown-height-medium: @text-input-height-default;

@dropdown-width-mini: 70px;
@dropdown-width-small: 100px;
@dropdown-width-medium: 280px;
@dropdown-width-big: 380px;

@dropdown-pading-mini-tb: 0;
@dropdown-pading-mini-lr: 6px;
@dropdown-pading-small-tb: 0;
@dropdown-pading-small-lr: 6px;
@dropdown-pading-medium-tb: 0;
@dropdown-pading-medium-lr: 10px;
@dropdown-pading-big-tb: 0;
@dropdown-pading-big-lr: 6px;

@color-accent-light-background: #EDF5F8;
@text-color-custom-elements-disabled: rgb(67, 82, 97);
@color-primary-disabled: @color-disabled-light;



.dropdown-wrapper {
    width: 100%;
    display: block;
    height: @dropdown-height-medium + @input-text-label-valued-height;
    margin: 4px 0 0 0;

    .label-valued {
        .label-valued();
        z-index: 300;
    }

    .dropdown-search {
        display: inline-block;
        background: white;
        box-sizing: border-box;
        position: relative;
        width: 100%;
        height: @dropdown-height-medium;
        border: 1px solid @border-color-main;
        box-sizing: border-box;


        .current-value {
            width: 100%;
            height: @dropdown-height-medium - 3px;
            line-height: @dropdown-height-medium - 3px;
            box-sizing: border-box;
            color: @text-color-light;
            cursor: pointer;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: left;
            padding: @dropdown-pading-medium-tb @dropdown-pading-medium-lr;
        }

        .clear-value {
            position: absolute;
            display: inline-block;
            vertical-align: middle;
            padding: 0;
            top: 0;
            right: 0;
            width: @dropdown-height-medium;
            height: @dropdown-height-medium;
            line-height: @dropdown-height-medium - 2px;
            cursor: pointer;
            color: @text-color-light;
            text-align: center;

            &:hover {
                color: @color-accent;
            }
        }

        .search-area {
            display: none;
        }

        .list-wrapper {
            position: relative;
            top: -2px;
            left: 0;
            width: 100%;
            height: 1px;
            z-index: 2000;

            .list {
                position: absolute;
                top: 0;
                left: -1px;
                width: 100%;
                border: 1px solid @color-accent;
                border-top: 1px solid @color-accent-light;
                background: white;
                display: none;
                margin: 0;
                list-style-type: none;
                padding: 0;
                max-height: 200px;
                overflow-y: scroll;

                .item {
                    cursor: pointer;
                    text-align: left;
                    font-size: 14px;
                    height: @dropdown-height-medium;
                    line-height: @dropdown-height-medium;
                    white-space: nowrap;
                    overflow-x: hidden;
                    text-overflow: ellipsis;
                    padding: 0 10px;
                    color: @text-color-lighten;

                    .checkbox {
                        display: none;
                    }

                    &.selected {
                        background: @color-block-background;

                        .checkbox {
                            display: block;
                            float: right;
                        }

                        &:hover {
                            background: @color-block-background;
                        }
                    }

                    &:hover {
                        background: @color-block-background;
                        color: @text-color-darken;
                    }

                    &.disabled {
                        color: @text-color-light;

                        &:hover {
                            background: none;
                            cursor: default;
                            color: @text-color-light;
                        }
                    }
                }
            }
        }
    }


    &.opened {
        .dropdown-search {
            border: 1px solid @color-accent;

            .current-value {
                display: none;
            }

            .search-area {
                display: block;
                box-sizing: border-box;
                height: @dropdown-height-medium !important;
                width: 100%;

                input {
                    box-sizing: border-box;
                    padding-top: 0;
                    padding-bottom: 0;
                    margin: 0;
                    display: inline-block;
                    width: 100% !important;
                    border: none !important;
                    vertical-align: middle;
                    padding-left: @dropdown-pading-medium-lr;
                    width: 100%;
                    height: @dropdown-height-medium - 2px !important;
                    line-height: @dropdown-height-medium - 2px !important;
                }

                input:focus {
                    border: none !important;
                }
            }

            .list-wrapper {
                .list {
                    display: block;
                }
            }
        }
    }

    &.closed {
        &:hover {
            .dropdown-search {
                border-color: @color-accent-light;
            }
        }
    }

    &.without-search {
        .dropdown-search {
            .search-area {
                display: none;
            }

            .list-wrapper {
                top: 1px;
            }
        }

        &.opened {
            .dropdown-search {
                .current-value {
                    display: block !important;
                }
            }
        }
    }

    &.selected {
        .dropdown-search {
            .current-value {
                color: @text-color-darken;
            }
        }
    }

    &.disabled {
        .dropdown-search {
            border-color: @border-color-main;
            background-color: lighten(@color-block-background, 1%);

            .current-value {
                cursor: default;
                //color: @text-color-lighten;
                &:hover {
                    color: @text-color-main;
                }
            }
        }

        &:hover {
            .dropdown-search {
                border-color: @border-color-main !important;
            }
        }
    }

    &.no-scroll {
        .dropdown-search {
            .list {
                overflow: auto !important;
            }
        }
    }

    &.no-label {
        height: @dropdown-height-medium;

        .label-valued {
            display: none;
        }
    }

    &.direction-top {
        .dropdown-search {
            .list-wrapper {
                top: -@dropdown-height-medium + 1px;
                height: 1px;

                .list {
                    bottom: 0;
                    top: auto;
                    max-height: 200px;
                    border-top-color: @color-accent;
                    border-bottom-color: @color-accent-light;
                }
            }
        }

        &.opened {
            .label-valued {
                visibility: hidden;
            }
        }
    }

    &.direction-bottom {
        .dropdown-search {
            .list-wrapper {
                top: -2px;
                height: 1px;

                .list {
                    top: 0;
                }
            }
        }
    }
}



.align-center > .simple-dropdown {
    margin-left: auto;
    margin-right: auto;
}


.align-center > .dropdown-search {
    margin-left: auto;
    margin-right: auto;
}
