@import '../../variables.less';

@dot-radius: 8px;
@dot-inner-radius: 4px;


radio-group {
    display: inline-block;
    box-sizing: border-box;
    vertical-align: middle;
    line-height: @dot-radius * 2;

    .radio-group-component {
        display: inline-block;
        box-sizing: border-box;
        vertical-align: middle;


        .radio-group-item-wrapper {
            display: inline-block;
            cursor: default;
            margin-right: @block-interval-step;

            > .dot {
                display: inline-block;
                vertical-align: middle;
                width: @dot-radius * 2;
                height: @dot-radius * 2;
                line-height: @dot-radius * 2;
                border: 1px solid @border-color-main;
                border-radius: @dot-radius;
                background-color: @color-block-background;
                transition: border-color linear 100ms;
                transition: background-color linear 100ms;

                > .dot-inner {
                    display: none;
                    margin: @dot-radius - @dot-inner-radius;
                    border-radius: @dot-inner-radius;
                    width: @dot-inner-radius * 2;
                    height: @dot-inner-radius * 2;
                    line-height: @dot-inner-radius * 2;
                    background: @border-color-light;
                    transition: background-color linear 100ms;
                }
            }

            > .display-name {
                display: inline-block;
                vertical-align: middle;
                line-height: @dot-radius * 2;
                margin-right: 10px;
                margin-left: 4px;
            }
        }

        .radio-group-item-wrapper:last-child {
            margin-right: 0;
        }

        .radio-group-item-wrapper:hover {
            cursor: pointer;

            > .dot {
                border-color: @border-color-dark;

                > .dot-inner {
                    display: inline-block;
                    background: @border-color-main;
                }
            }

            > .display-name {
                color: @text-color-darken;
            }
        }


        .radio-group-item-wrapper.selected {
            > .dot {
                border-color: @color-accent-darken;
                background: @color-body-background;


                > .dot-inner {
                    display: inline-block;
                    background-color: @color-accent;
                }
            }
        }


        .radio-group-item-wrapper.selected:hover {
            > .dot {
                > .dot-inner {
                }
            }
        }
    }

    .radio-group-component.vertical {
        .radio-group-item-wrapper {
            display: block;
            margin-bottom: @block-interval-step;
        }
    }
}