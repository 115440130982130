@import '../../../global/variables.less';

.report-filters {
    min-height: 160px;

    .no-filters {
        padding-top: 48px;
        text-align: center;
    }

    .btn-add-filter {
        margin-left: 0;
    }

    .filter-selector {
        .selected-count {
            font-size: 12px;
            color: @text-color-lighten;
        }
    }
}