@import '../../../global/variables.less';

@search-wrapper-height: 42px;
@search-list-height: 158px;
@search-item-height: 42px;

.report-filter {
    margin-bottom: @block-interval-step * 2;
    border: 1px solid @color-body-background;
    z-index: 50;

    .search-outer-wrapper {
        margin-top: 3px;
        z-index: 60;
        box-sizing: border-box;
        position: relative;
        top: 0;
        left: 0;
        height: @search-wrapper-height;

        .ng-scrollbar-visible, ng-scrollbar {
            background-color: transparent !important;
        }

        .search-wrapper {
            height: @search-wrapper-height;
            box-sizing: border-box;
            z-index: 100;
            position: relative;
            top: 0;
            left: 0;

            .search-border {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: @search-wrapper-height;
                z-index: 250;
                box-sizing: border-box;
                border: 1px solid @border-color-main;
                background: @color-body-background;

                .search-reset-wrapper {
                    width: 32px;
                    height: @search-wrapper-height;
                    float: right;

                    .search-reset-icon {
                        width: 32px;
                        height: @search-wrapper-height;
                        text-align: center;
                        line-height: @search-wrapper-height - 2px;

                        > .fa {
                            color: @text-color-light;
                        }
                    }

                    .search-reset-icon:hover {
                        cursor: pointer;

                        > .fa {
                            color: @color-accent-darken;
                        }
                    }
                }

                .search-input-wrapper {
                    margin-right: 32px;
                    height: @search-wrapper-height - 2px;

                    input {
                        box-sizing: border-box;
                        height: 100%;
                        width: 100%;
                        margin: 0;
                        padding: 0 0 0 10px;
                        line-height: @search-wrapper-height - 2px;
                        color: @text-color-lighten;
                        outline: none;
                        display: block;
                        border-width: 0;
                    }

                    input:focus {
                        border-width: 0;
                    }
                }
            }

            .search-list {
                position: absolute;
                box-sizing: border-box;
                top: @search-wrapper-height - 1px;
                left: 0;
                width: 100%;
                height: @search-list-height;
                z-index: 200;
                border: 1px solid @border-color-main;
                border-top-color: @border-color-light;
                background: @color-body-background;

                .ng-scrollbar-visible {
                    padding: 4px !important;
                }


                .search-list-item {
                    height: @search-item-height;
                    line-height: @search-item-height;
                    box-sizing: border-box;
                    padding: 2px 8px;
                    color: @text-color-lighten;
                    white-space: nowrap;

                    > span {
                        font-size: 14px;
                        height: @search-item-height - 6px;
                        line-height: @search-item-height - 6px;
                        display: inline-block;
                        box-sizing: border-box;
                    }

                    > .item-id {
                        background: @color-block-background;
                        color: @text-color-lighten;
                        transition: all linear 100ms;
                        padding: 0 4px;
                        margin-right: 4px;
                        border-radius: 3px;
                        height: @search-item-height - 20px;
                        line-height: @search-item-height - 21px;
                        margin-top: 10px;
                    }

                    > .item-name {
                        white-space: nowrap;
                    }

                    &:hover {
                        color: @text-color-darken;
                        background: @color-block-background;
                        cursor: pointer;

                        > .item-id {
                            color: @color-accent-darken;
                        }
                    }

                    &.selected {
                        &:hover {
                            cursor: default;

                            > span {
                                color: @text-color-lighten;
                            }

                            > .item-id {
                                background: @color-block-background;
                                color: @text-color-lighten;
                            }
                        }
                    }
                }
            }
        }

        &:hover {
            .search-border {
                border-color: @color-accent-lighten;
            }
        }

        &.opened {

            z-index: 70;

            .search-wrapper {
                height: @search-wrapper-height + @search-list-height - 1px;

                .search-border {
                    border-color: @color-accent;
                    border-bottom-color: @color-accent-light;
                }

                .search-list {
                    border-color: @color-accent;
                    border-top-color: @color-accent-light;
                }
                /*
                -webkit-box-shadow: 0px 0px 18px 1px darken(@color-block-background, 10%);
                -moz-box-shadow: 0px 0px 18px 1px darken(@color-block-background, 10%);
                box-shadow: 0px 0px 18px 1px darken(@color-block-background, 10%);
                */
            }
        }
    }

    .selected-list {
        padding-left: @block-interval-step * 2;
        padding-top: 4px;
        min-height: 45px;
        box-sizing: border-box;

        .selected-list-item {
            display: inline-block;
            box-sizing: border-box;
            height: @search-wrapper-height;
            line-height: @search-wrapper-height - 1px;
            padding: 0 12px 0 8px;
            background: @color-block-background;
            border-radius: 3px;
            margin-right: 6px;
            margin-bottom: 6px;

            > .fa {
                margin-left: 6px;
                color: @text-color-lighten;
            }

            &:hover {
                cursor: pointer;

                > .fa {
                    color: @color-accent-darken;
                }
            }

            > span {
                display: inline-block;
                box-sizing: border-box;
                vertical-align: top;
                height: @search-wrapper-height - 1px;
                line-height: @search-wrapper-height - 1px;
            }

            > .item-id {
                border: 1px solid @border-color-main;
                background: @border-color-main;
                margin-top: 10px;
                border-radius: 3px;
                padding: 0 4px;
                margin-right: 6px;
                height: @search-wrapper-height - 21px;
                line-height: @search-wrapper-height - 23px;
                color: @text-color-inverse-active;
            }

            > .item-name {
                height: @search-wrapper-height;
                line-height: @search-wrapper-height - 1px;
                margin-right: 4px;
                max-width: 260px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}


.report-filter.report-filter-string {
    .input-wrapper {
        height: @search-wrapper-height;
        padding-top: 3px;


        input {
            height: @search-wrapper-height;
            width: 100%;
            box-sizing: border-box;
            padding: 0 10px;
        }
    }

    .btn-add-string {
        margin-top: 3px;
        padding-left: 12px;
        padding-right: 12px;
        text-align: center;

        .fa {
            margin-right: 0;
        }

        &[disabled] {
            .fa {
                color: @text-color-inverse;
            }

            &:hover .fa {
                color: @text-color-inverse;
            }
        }
    }

    .selected-list {
        .selected-list-item{
            padding-left: @block-interval-step;
        }
    }
}
