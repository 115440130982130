@import '../../global/variables.less';

.users-actions-info {
    .btn-add-container {
        padding-top: 16px;

        .btn {
            margin: @block-interval-step 0 0 0;
        }
    }

    .global-info {
        padding-top: @block-interval-step;
        padding-bottom: @block-interval-step;
        text-align: right;

        .global-info-item {
            display: inline-block;
            border-right: 2px solid @border-color-light;
            padding: @block-interval-step @block-interval-step*3;
            width: 80px;

            .global-info-key {
                text-transform: uppercase;
                color: @text-color-lighten;
            }

            .global-info-value {
                font-size: 24px;
                color: @color-primary;
            }

            .global-info-value:hover {
                text-decoration: underline;
                color: @color-primary-dark;
                cursor: pointer;
            }
        }

        .global-info-item:last-child {
            border: none;
        }
    }
}
