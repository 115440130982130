@import '../../variables.less';

@name-cell-height: 36px;
@name-cell-id-width: 60px;

.dt-name-cell {
    height: @name-cell-height;
    padding-top: 3px;
    padding-bottom: 3px;

    .str-1 {
        font-size: @text-size-h4;
        height: @name-cell-height / 2;
        line-height: @name-cell-height / 2;

        .name {
            color: @text-color-darken;
        }
    }

    .str-2 {
        height: @name-cell-height / 2;
        line-height: @name-cell-height / 2;

        .id {
            font-size: @text-size-small;
            display: inline-block;
            color: @text-color-lighten;
            margin-right: 8px;

            &::before {
                content: '#';
            }
        }

        .description {
            font-size: @text-size-small;
            display: inline-block;
            color: @text-color-light;
        }

        .content {
            font-size: @text-size-small;
            display: inline-block;
            color: @text-color-light;
        }
    }
}
