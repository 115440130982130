@import '../../../global/variables.less';

@item-height: 36px;

.report-column-selector {
    .column-group {
        .column-group-header {
            height: @item-height;
            line-height: @item-height;
            font-weight: bolder;
            color: @text-color-lighten;
            border-bottom: 2px solid @border-color-light;
            margin-bottom: 4px;
            padding-left: @block-interval-step/2;
        }

        .columns-group-list {
            margin-bottom: @block-interval-step;

            .column-item {
                height: @item-height;
                line-height: @item-height;
                box-sizing: border-box;
                margin-right: 6px;
                padding-left: @block-interval-step*0.5;
                border-radius: 4px;

                .name {
                    display: inline-block;
                    height: @item-height;
                    line-height: @item-height;
                }

                .add, .remove {
                    display: inline-block;
                    height: @item-height;
                    width: @item-height;
                    border-radius: 4px;
                    line-height: @item-height;
                    float: right;
                    color: @text-color-light;
                    text-align: center;
                    transition: color linear 100ms;
                }

                .add {
                    .fa {
                        margin-left: 2px;
                    }
                }

                .remove {
                    display: none;

                    .fa {
                        color: @color-accent-pale;
                    }

                    &:hover {
                        cursor: pointer;

                        .fa {
                            color: @color-accent;
                        }
                    }
                }

                &:hover {
                    background: @color-block-background;
                    cursor: default;

                    .add {
                        background-color: @border-color-light;
                        color: @text-color-lighten;

                        &:hover {
                            background: @color-primary;
                            color: @text-color-inverse-active;
                            cursor: pointer;
                        }
                    }
                }

                &.disabled {
                    color: @text-color-light;

                    .add {
                        display: none;
                    }

                    .remove {
                        display: block;
                    }

                    &:hover {
                        cursor: auto;
                        background: @color-body-background;
                    }
                }
            }
        }
    }

    
}
