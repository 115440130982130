@import '../../../global/variables.less';

@summary-item-height: 140px;
@block-charts-details-height: 260px;

.page-dashboard {

    @_date_period_height: 28px;

    .block-date-periods {
        height: @_date_period_height;
        text-align: right;
        margin-top: @block-interval-step;

        .date-periods {
            height: @_date_period_height;
            display: inline-block;

            .tabs {
                height: @_date_period_height;
                padding-right: @block-interval-step;

                .tab {
                    white-space: nowrap;
                    box-sizing: border-box;
                    transition: background-color linear 100ms;
                    transition: color linear 100ms;

                    > span {
                        display: inline-block;
                        height: @_date_period_height;
                        line-height: @_date_period_height - 2px;
                        vertical-align: top;
                        margin: 0 @block-interval-step;
                    }
                }

                .tab.selected {
                    background: @color-primary;
                    border-radius: 2px;
                    color: @text-color-inverse-active;
                }
            }
        }
    }

    .block-summary {
        background: @color-block-background;
        padding: 0 @block-interval-step;
        margin: @block-interval-step 0;
        border-top: 1px solid @border-color-light;
        border-bottom: 1px solid @border-color-light;
        height: @summary-item-height;
        box-sizing: border-box;

        .summary-item {
            float: left;
            padding: @block-interval-step;
            height: @summary-item-height;
            box-sizing: border-box;

            .summary-item-inner {

                .metric-warning-wrapper {
                    position: relative;
                    z-index: 200;
                    top: 3px;
                    left: 0;
                    width: 100%;
                    height: 1px;

                    .metric-warning {
                        position: absolute;
                        right: 0;
                        width: 32px;
                        height: 32px;
                        top: -4px;
                        line-height: 32px;
                        text-align: center;

                        > .fa {
                            color: @color-warning-default;
                        }

                        &:hover {
                            > .fa {
                                cursor: pointer;
                                color: darken(@color-warning-default, 10%);
                            }
                        }
                    }

                    .metric-warning-text {
                        position: absolute;
                        right: 0;
                        width: 100%;
                        min-height: 86px;
                        box-sizing: border-box;
                        top: 28px;
                        background: @color-warning-light;
                        border: 1px solid @color-warning-default;
                        border-radius: 3px;
                        padding: @block-interval-step / 2;
                    }
                }

                .metric-name {
                    padding: 0 @block-interval-step;
                    height: 24px;
                    line-height: 24px;
                    font-size: 16px;
                    text-align: center;
                    margin-bottom: 24px;
                    color: @text-color-lighten;
                }

                .growth-value {
                    margin-top: 4px;
                    height: 20px;
                    text-align: center;
                    margin-bottom: 20px;

                    > span {
                        color: @text-color-inverse-active;
                        border-radius: 3px;
                        display: inline-block;
                        font-weight: bolder;
                        line-height: 20px;
                        font-size: 12px;
                        padding: 0 6px;
                    }
                }

                .metric-value {
                    margin-top: 4px;
                    height: 24px;
                    line-height: 24px;
                    text-align: center;
                    font-size: 22px;
                    margin-bottom: 8px;
                    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
                }

                .metric-selector {
                    height: 32px;
                    position: relative;
                    z-index: 100;
                    width: 100%;
                    height: 1px;
                    top: 0;
                    left: 0;

                    .axis-left, .axis-right {
                        position: absolute;
                        height: 32px;
                        width: 32px;
                        top: -36px;
                        line-height: 32px;
                        text-align: center;
                        border-radius: 16px;
                        opacity: 0.7;
                        transition: color linear 100ms;
                        transition: opacity linear 100ms;

                        &:hover {
                            cursor: pointer;
                            opacity: 1;
                        }
                    }

                    .axis-left {
                        left: 0;

                        .fa {
                            transform: rotate(45deg);
                            position: relative;
                            left: -1px;
                        }
                    }

                    .axis-right {
                        right: 0;

                        .fa {
                            transform: rotate(-45deg);
                            position: relative;
                            //left: 1px;
                            top: -1px;
                        }
                    }
                }

                .metric-color-marker {
                    height: 8px;
                }
            }


            &.positive {
                .growth-value > span {
                    background: @color-success-pale;
                }
            }

            &.negative {
                .growth-value > span {
                    background: @color-warning-default;
                }
            }

            &.unselected {
                .axis-left, .axis-right {
                    color: @text-color-lighten;

                    &:hover {
                        color: @text-color-darken;
                    }
                }
            }

            &.selected-left, &.selected-right {
                .axis-left, .axis-right {
                    color: @text-color-inverse-active;
                }
            }

            &.selected-right .axis-left {
                display: none;
            }

            &.selected-left .axis-right {
                display: none;
            }
        }

        .summary-item-divider {
            height: @summary-item-height - 2px - 2*@block-interval-step;
            width: 2px;
            float: left;
            margin-top: @block-interval-step;
            margin-bottom: @block-interval-step;
            background: @border-color-light;
        }

        &.block-summary-admin {
            .summary-item {
                width: ~"calc(16.66% - 2px)";
            }
        }
    }


    .block-chart-main {
        margin-bottom: @block-interval-step;

        .chart-main {
            width: 100%;
            height: 300px;
            display: block;
        }
    }


    .block-charts-details {
        @_header-height: 42px;

        .block-charts-details-item {
            width: 33.33%;
            float: left;
            height: @block-charts-details-height - @_header-height;

            > .item-header {
                height: @_header-height;
                line-height: @_header-height;
                text-align: center;
                background: @color-block-background;
                border-top: 1px solid @border-color-light;
                border-bottom: 1px solid @border-color-light;
            }


            .chart-details {
                width: 100%;
                box-sizing: border-box;
                padding: @block-interval-step @block-interval-step*2;
                height: 100%;
                display: block;
            }
        }

        .block-charts-details-divider {
            height: @_header-height - 2px - @block-interval-step;
            width: 2px;
            float: left;
            margin-left: @block-interval-step;
            margin-top: @block-interval-step / 2;
            margin-bottom: @block-interval-step / 2;
            background: @border-color-light;
        }
    }
}
