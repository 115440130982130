@import '../../../global/variables.less';

.page-banner-edit {
    margin-top: @block-interval-step;
}


.page-banner-edit-html, .page-banner-edit-interactive {
    textarea {
        width: 100% !important;
        margin-top: 0 !important;
    }

    div.code {
        .outer {
            width: 100%;
            height: 180px;
            border: 1px solid @border-color-main;
            box-sizing: border-box;
            overflow-y: scroll;
        }

        .inner {
            width: 100%;
            min-height: 100%;
            padding-left: 36px;
            padding-top: 10px;
            padding-bottom: 10px;
            background-image: url(../../../../assets/images/code-numbers.png);
            background-repeat: no-repeat;
            font-family: Courier New, Courier, monospace;
            box-sizing: border-box;
            overflow: visible;
            white-space: pre;
        }

        .inner:focus {
            color: @text-color-darken;
        }
    }
}


.page-banner-video-inline {
    .video-file-item {
        .loader-container {
        }

        .icon-container {
            // todo
            // get this sizes from variables
            margin-top: @block-interval-step * 2;
            height: 74px;
            box-sizing: border-box;

            > .fa {
                height: 44px;
                line-height: 44px;
                width: 100%;
                text-align: center;
            }
        }
    }
}


.page-banner-native-mobile {
    .preview-container {
        width: 100%;
        margin-top: @block-interval-step*2;
    }
}
