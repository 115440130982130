@import '../variables.less';

@radio-tabs-height: 36px;

.radio-tabs {
    height: @radio-tabs-height;
    line-height: @radio-tabs-height - 2px;
    display: inline-block;
    background: @background-control-inactive;
    border-radius: 3px;
    border: 1px solid @border-color-light;

    .tab {
        padding: 0 16px;
        height: @radio-tabs-height;
        display: inline-block;
    }

    .tab:hover {
        cursor: pointer;
        background-color: @background-control-inactive-hover;
    }

    .tab.tab-active {
        background-color: @background-control-active;
    }

    .tab.tab-active:hover {
        background-color: @background-control-active-hover;
    }
}