@import '../../variables.less';

@action-element-height: 32px;

dt-actions {
    white-space: nowrap;

    .dt-actions-wrapper {
        display: block;
        height: @action-element-height;
        white-space: nowrap;

        > .dt-actions-container {
            height: @action-element-height;
            //background: @color-block-background;
            border-radius: 3px;
            //border: 1px solid @border-color-light;
            white-space: nowrap;

            dt-action {
                display: inline-block;
                width: @action-element-height;
                height: @action-element-height;
                text-align: center;

                .action-icon {
                    display: inline-block;
                    width: @action-element-height;
                    height: @action-element-height;
                    text-align: center;
                    box-sizing: border-box;
                    transition: background-color linear 100ms;
                    border-radius: 3px;

                    > * {
                        display: inline-block;
                        width: @action-element-height;
                        height: @action-element-height;
                        line-height: @action-element-height;
                        font-size: 16px;
                        color: @text-color-lighten;
                        transition: color linear 100ms;
                    }

                    &:hover {
                        background-color: @color-primary-lighten;
                        cursor: pointer;

                        > * {
                            color: @text-color-inverse;
                        }
                    }
                }
            }
        }
    }
}