@import '../variables.less';

.collapsable-area {
    .collapsable-area-header {
        height: 42px;
        display: block;
        border: 1px solid @border-color-light;
        border-width: 0 0 1px 0;

        &:hover {
            border-color: @border-color-main;
        }

        .header-title {
            height: 42px;
            line-height: 42px;
            float: left;
            width: 49%;
            box-sizing: border-box;
            font-size: 16px;
            color: @text-color-lighten;

            &:hover {
                color: @color-primary-dark;
                cursor: pointer;
            }
        }

        .header-state {
            height: 42px;
            line-height: 42px;
            margin-left: 51%;
            width: 49%;
            box-sizing: border-box;
            text-align: right;
            padding-right: @block-interval-step;

            .fa {
                color: @text-color-lighten;
            }

            &:hover {
                cursor: pointer;

                .fa{
                    color: @color-primary;
                }
            }
        }
    }

    .collapsable-area-content {
        padding-top: @block-interval-step;
    }
}
