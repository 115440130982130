@import '../../variables.less';

@input-height: @text-input-height-default;

.utm-parameters {
    .parameters-pair {
        .input-wrapper {
            display: block;
            height: @input-height;
            box-sizing: content-box;
            border: none;
        }

        .utm-parameter-equality-sign,
        .utm-parameter-delete-icon,
        .utm-parameter-add-icon {
            line-height: @input-height;
            display: inline-block;
            vertical-align: middle;
            font-size: 18px;
            padding-top: 20px;
            transition: opacity linear 100ms;
        }

        .utm-parameter-add-icon {
            margin-top: 4px;
            color: @color-primary-pale;
        }

        .utm-parameter-add-icon:hover {
            color: @color-primary;
            cursor: pointer;
        }

        .utm-parameter-delete-icon {
            margin-top: 4px;
            color: @color-warning-default-pale;
        }

        .utm-parameter-delete-icon:hover {
            color: @color-warning-default;
            cursor: pointer;
        }
    }

    > .errors {
        > .error {
        }
    }
}
