@import '../../../global/variables.less';


.page-audiences-edit {
    .audience-constructor-header {
        .page-block-header();
        font-size: @text-size-main !important;
    }

    @constructor-height: 490px;
    @search-height: 52px;
    @search-input-height: 34px;
    @tabs-height: 24px;

    .audience-constructor {
        position: relative;
        z-index: 1;
        margin-top: @block-interval-step;

        .sources-list {
            .page-submenu(100%, @constructor-height);
            padding-top: 0;
            width: 100%;

            .ng-scrollbar-visible {
                padding-top: 0 !important;
                padding-bottom: 0 !important;
            }
        }


        .search-wrapper {
            height: @search-height;

            > .search-container {
                width: ~"calc(100% - @{block-interval-step} * 3 - 6px)";
                height: @search-input-height;
                border: 1px solid @border-color-light;
                border-radius: 2px;
                margin-left: @block-interval-step;
                margin-right: @block-interval-step * 3;
                box-sizing: border-box;

                > input.search, > input.search:focus {
                    width: ~"calc(100% - 20px)";
                    height: @search-input-height - 2px;
                    line-height: @search-input-height - 2px;
                    margin: 0;
                    padding: 0 6px;
                    box-sizing: border-box;
                    border: none;
                }

                > .fa {
                    width: 20px;
                    height: @search-input-height - 2px;
                    line-height: 26px;
                    color: @color-primary-light;
                    vertical-align: middle;

                    &:hover {
                        cursor: pointer;
                        color: @color-accent-darken;
                    }
                }
            }

            &.focused {
                > .search-container {
                    border-color: @color-accent;
                }
            }
        }



        .all-segments-list {
            height: @constructor-height - @search-height;

            .segment {
                height: 56px;
                line-height: 56px;
                margin-left: 24px;
                background: @color-block-background;
                margin: @block-interval-step 0 @block-interval-step @block-interval-step;
                border: 1px solid transparent;
                box-sizing: border-box;
                margin-right: 8px;

                > .id {
                    padding-left: @block-interval-step;
                }

                .operation {
                    color: @color-accent;
                    font-weight: bolder;
                    text-transform: uppercase;
                }

                .segment-name {
                    height: 28px;
                    line-height: 28px;
                }

                .segment-description {
                    height: 28px;
                    line-height: 14px;
                    font-size: 12px;
                    color: @text-color-light;
                }

                > .count {
                    text-align: right;
                    padding-right: @block-interval-step;
                }


                &:first-child {
                    margin-top: 0;
                }

                &:hover {
                    cursor: pointer;
                    border: 1px solid @color-primary-light;
                }

                &.selected {
                    background-color: lighten(@color-primary-light, 12%);

                    &:hover {
                        border-color: @color-primary;
                    }
                }
            }
        }

        .selected-segments-wrapper {
            .auditory-operation-title {
                height: @search-height + @tabs-height + @block-interval-step;

                > .title-wrapper {
                    margin-bottom: @block-interval-step;

                    > .title {
                        height: @search-input-height;
                        line-height: @search-input-height;
                        display: inline-block;
                    }
                }

                .tabs-wrapper {
                    height: @tabs-height;

                    .tabs {
                        height: @tabs-height;

                        .tab {
                            height: @tabs-height;
                            line-height: @tabs-height;
                            border: 1px solid @border-color-light;
                        }
                    }
                }
            }

            .selected-segments-container-wrapper {

                .selected-segments-container {
                    height: @constructor-height - @search-height - @block-interval-step - @tabs-height;

                    .selected-segment {
                        height: 30px;
                        margin: 4px 8px;
                        padding: 4px 0;
                        border-bottom: 1px solid @border-color-light;


                        > .name {
                            display: inline-block;
                            height: 30px;
                            line-height: 30px;
                            padding: 0 14px;
                            width: ~"calc(100% - 20px)";
                            float: left;
                            box-sizing: border-box;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }

                        > .close {
                            height: 30px;
                            line-height: 30px;
                            display: block;
                            float: right;
                            width: 20px;
                            color: @text-color-light;
                        }

                        > .close:hover {
                            cursor: pointer;
                            color: @color-accent-darken;
                        }

                        &:last-child {
                            border: none;
                        }
                    }
                }
            }
        }
    }
}
