@import '../../../global/variables.less';

@report-header-height: 32px;
@_additional_settings_height: 380px;

.page-report-view {


    .report-settings {
        z-index: 100;
        background: @color-block-background;
        border-bottom: 1px solid @border-color-main;

        .report-main-settings-wrapper {
            padding: 0 @block-interval-step*2 @block-interval-step @block-interval-step*2;

            .additional-settings-toggler {
                padding-top: 4px;

                .fa {
                    margin-left: 8px;
                    margin-right: 2px;
                }
            }
        }


        .btn-container {
            padding-top: 22px;
            padding-left: @block-interval-step*2;
        }

        .report-additional-settings-wrapper {
            height: 1px;
            width: 100%;
            left: 0;
            right: 0;
            position: relative;
            z-index: 200;

            .tabs {
                border: 1px solid @border-color-light;

                .tab {
                    line-height: 34px !important;
                }
            }

            .report-additional-settings {
                z-index: 300;
                position: absolute;
                width: 100%;
                box-sizing: border-box;
                height: @_additional_settings_height;
                left: 0;
                top: -12px;
                background: @color-block-background;
                border-bottom: 1px solid @border-color-main;
                padding: 0 @block-interval-step*2 @block-interval-step @block-interval-step*2;

                &:after {
                    content: "";
                    display: block;
                    width: 100%;
                    height: @block-interval-step * 1.5;
                    position: absolute;
                    width: 100%;
                    box-sizing: border-box;
                    left: 0;
                    top: @_additional_settings_height;
                    background: rgba(128,128,128,0.15);
                    z-index: 400;
                    background: -moz-linear-gradient(top, rgba(128,128,128,0.15) 0%, rgba(255,255,255,0) 100%);
                    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(128,128,128,0.15)), color-stop(100%, rgba(255,255,255,0)));
                    background: -webkit-linear-gradient(top, rgba(128,128,128,0.15) 0%, rgba(255,255,255,0) 100%);
                    background: -o-linear-gradient(top, rgba(128,128,128,0.15) 0%, rgba(255,255,255,0) 100%);
                    background: -ms-linear-gradient(top, rgba(128,128,128,0.15) 0%, rgba(255,255,255,0) 100%);
                    background: linear-gradient(to bottom, rgba(128,128,128,0.15) 0%, rgba(255,255,255,0) 100%);
                    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0 );
                }

                .cells-container {
                    min-height: 250px;

                    .column-line {
                        display: block;
                        margin-bottom: @block-interval-step * 2;

                        .column-group {
                            display: block;

                            .column-item {
                                display: inline-block;
                                min-width: 140px;

                                .checkbox-component{
                                    margin-top: 0;
                                    padding-top: 8px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }


    .chart-wrapper {
        .chart {
            width: 100%;
            height: 300px;
            display: block;
        }
    }


    .data-table {
        thead {
            height: @report-header-height;
            line-height: @report-header-height;

            th {
                height: @report-header-height;
                line-height: @report-header-height;
                white-space: nowrap;
                border-bottom: 3px solid @border-color-light;

                &.header-cell-metric {
                    text-align: right;

                    &.hovered {
                        cursor: pointer;
                        border-bottom-color: @border-color-main;
                    }
                }


                &.header-cell-ordering {
                    width: @report-header-height;
                    padding: 0;


                    .column-ordering {
                        height: @report-header-height;
                        width: @report-header-height;

                        .column-ordering-desc, .column-ordering-asc {
                            height: @report-header-height/2 - 2px;
                            width: @report-header-height;
                            text-align: center;
                            line-height: @report-header-height/2 - 2px;

                            .fa {
                                height: 100%;
                                line-height: @report-header-height/2 - 2px;
                                color: @text-color-light;
                            }

                            &:hover {
                                cursor: pointer;

                                .fa {
                                    color: @text-color-main;
                                }
                            }
                        }

                        &.asc .column-ordering-asc .fa,
                        &.desc .column-ordering-desc .fa {
                            color: @color-accent;
                        }
                    }

                    &.hovered {
                        border-bottom-color: @border-color-main;
                    }
                }

                &.selected {
                    border-bottom-color: @color-primary-light;

                    &.hovered {
                        border-bottom-color: @color-primary;
                    }
                }
            }
        }

        tbody {
            td {
                white-space: nowrap;
            }
        }

        tfoot {
            td {
                white-space: nowrap;
            }
        }
    }
}
