@import '../../../global/variables.less';

@selector-height: 130px;

@text-height-unselected: 40px;


.type-selector {
    display: -webkit-flex;
    display: flex;
    flex-wrap: nowrap;
    height: @selector-height;
    padding-left: 10%;
    padding-right: 25%;
    box-sizing: content-box;
    background: @color-block-background;
    border: 1px solid @border-color-light;

    .type-selector-item {
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        box-sizing: border-box;
        position: relative;
        border: 2px solid transparent;
        transition: background-color linear 100ms;
        transition: border-color linear 100ms;

        > .text {
            color: @text-color-lighten;
            text-align: center;
            padding: 0 @block-interval-step;

            > .title {
                margin-top: @selector-height/2 - 8px;
                height: 20px;
                line-height: 20px;
                transition: all linear 200ms;
                font-size: @text-size-h2;
                white-space: nowrap;
            }

            > .description {
                display: none;
                margin-top: 16px;
                height: 12px;
                line-height: 12px;
                color: @text-color-light;
                font-size: 12px;
                margin-left: @block-interval-step*2;
                margin-right: @block-interval-step*2;
            }
        }

        &:hover {
            background: @color-body-background;
        }

        &.disabled, &.disabled:hover {
            cursor: default !important;
            background: @color-block-background;

            > .text {
                color: @text-color-light !important;
            }
        }

        &.selected {
            background: @color-body-background;
            border-color: @color-primary;
            height: @selector-height + 12px;
            top: -6px;

            > .text {
                position: relative;
                top: 6px;

                > .title {
                    margin-top: @selector-height/2 - 18px;
                    color: @text-color-darken;
                    font-size: @text-size-h4;
                }

                > .description {
                    display: block;
                }
            }
        }
    }

    &:not(.readonly) {

        .type-selector-item:hover {
            opacity: 1;
            cursor: pointer;

            > .text {
                color: @text-color-main;
            }
        }
    }
}
