@import '../variables.less';

.activity-changer {
    display: inline-block;
    width: 32px;
    height: 22px;
    border: 1px solid @border-control-inactive;
    background: @background-control-inactive;
    border-radius: 11px;
    box-sizing: content-box;
    cursor: pointer;
    transition: all ease-in 100ms;
    text-align: left;

    .pointer {
        display: inline-block;
        width: 16px;
        height: 16px;
        border: 1px solid @border-control-inactive;
        background: white;
        border-radius: 8px;
        margin: 2px;
        transition: all ease-in 100ms;
    }


    &:hover {
        @_border-color: lighten(desaturate(@color-success-default, 40%), 20%);
        border-color: @_border-color;
        .pointer {
            border-color: @_border-color;
        }
    }


    &.active {
        border-color: @color-success-default;
        background: @color-success-lighten;
        text-align: right;

        .pointer {
            border-color: @color-success-default;
        }

        &:hover {
            border-color: @color-success-darken;

            .pointer {
                border-color: @color-success-darken;
                background: @color-success-light;
            }
        }
    }

    &.disabled {
        cursor: default;
        border-color: @border-control-inactive-hover;

        .pointer {
            border-color: @border-control-inactive-hover;
        }
    }
}
