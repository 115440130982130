@import '../../global/variables.less';


.banner-image-loader {
    width: 100%;
    border-width: 2px;
    border-style: dashed;
    border-radius: 6px;
    padding: @block-interval-step * 2;
    box-sizing: border-box;
    transition: background linear 200ms;

    &.no-previews {
        .diagonal-lines-background(@color-accent-light, @color-body-background, lighten(@color-accent-light, 11%));
    }

    &.with-previews {
        border-color: @color-accent-light;
    }

    &.dragging {
        .diagonal-lines-background(@color-accent-lighten, @color-body-background, lighten(@color-accent-light, 8%));
    }

    &.with-previews.dragging {
        .diagonal-lines-background(@color-accent-light, @color-body-background, lighten(@color-accent-light, 11%));
    }

    input[type=file] {
        visibility: hidden;
        width: 1px;
        margin: 0;
        padding: 0;
    }

    > .message {
        > .text {
            text-align: center;
            margin-bottom: @block-interval-step * 2;
        }

        > .text:first-child {
            margin-top: @block-interval-step * 6;
        }

        > .btn-search-container {
            margin-bottom: @block-interval-step * 6;
            text-align: center;
        }

        .btn {
            background: @color-body-background !important;
        }
    }


    @preview-block-height: 200px;
    @preview-block-width: 200px;

    .flex-center() {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -ms-flex-align: center;
        -webkit-align-items: center;
        -webkit-box-align: center;
        align-items: center;
    }

    .previews-container {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(@preview-block-width, 1fr));
        grid-gap: @block-interval-step * 2;

        .preview {
            height: @preview-block-height + 1px;
            border: 1px solid @border-color-light;
            background: @color-body-background;

            > .image {
                height: @preview-block-height;
                width: 100%;
                .flex-center();
                box-sizing: border-box;
                padding: 8px;

                > img {
                    max-width: 100%;
                    max-height: 100%;
                    margin-left: auto;
                    margin-right: auto;
                }
            }

            .info-wrapper {
                width: 100%;
                height: 1px;
                position: relative;

                .info-mask, .info-data {
                    opacity: 0;
                    width: 100%;
                    height: @preview-block-height;
                    position: absolute;
                    top: -@preview-block-height;
                    left: 0;
                    transition: opacity ease-out 140ms;
                }

                .info-mask {
                    background: white;
                }

                .info-data {
                    width: 100%;
                    height: 100%;

                    .info-data-content {
                        height: @preview-block-height;

                        > .img-name, > .img-dimensions, > .img-size {
                            height: 32px;
                            line-height: 32px;
                            text-align: center;
                            padding: 0 @block-interval-step;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            background: fade(@color-block-background, 90%);
                            color: @text-color-darken;
                        }


                        > .img-name {
                            border-radius: 6px 6px 0 0;
                        }

                        > .img-size {
                            border-radius: 0 0 6px 6px;
                        }

                        > .img-delete {
                            height: @preview-block-height - 32px * 3;
                            text-align: center;
                            padding-top: (@preview-block-height - 32px * 4) / 2;
                            box-sizing: border-box;

                            .fa {
                                font-size: 32px;
                                width: 42px;
                                height: 42px;
                                line-height: 42px;
                                color: @text-color-lighten;
                                border-radius: 21px;
                                border: 1px solid @border-color-light;
                                background: fade(white, 50%);
                                transition: all linear 200ms;
                            }

                            .fa:hover {
                                cursor: pointer;
                                color: @color-accent-darken;
                                border-color: @color-accent-lighten;
                                background: fade(white, 80%);
                            }
                        }
                    }
                }
            }
        }

        .preview:hover {
            border-color: @border-color-main;

            > .image {
                -webkit-filter: blur(1px);
                -moz-filter: blur(1px);
                filter: blur(1px);
            }

            .info-mask {
                opacity: 0.5;
            }

            .info-data {
                opacity: 1;

                .info-data-content {
                }
            }
        }

        .load-another {
            border: 1px solid @border-color-light;
            height: @preview-block-height;
            width: @preview-block-width;
            display: none;
        }
    }


    > .actions {
        margin-top: @block-interval-step * 2;
        display: none;
    }
}


.banner-image-loader.with-previews {
    > .message {
        text-align: center;
        margin-bottom: @block-interval-step * 4;

        > .text {
            display: inline-block;
            margin: 0;
            line-height: 24px;
        }

        > .btn-search-container {
            margin: 0 0 0 @block-interval-step;
            text-align: center;
            display: inline-block;
        }
    }

    >.actions{
        display: block;
    }
}